var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"deskfooter"},[_c('div',{staticClass:"div-block-446"},[_c('div',{staticClass:"div-block-447"},[_c('div',{staticClass:"div-block-982",staticStyle:{"text-align":"center"}},[_c('a',{staticClass:"w-inline-block",attrs:{"href":"index.html"}},[_c('img',{staticClass:"image-6",attrs:{"src":_vm.footlogo,"alt":""}})]),_c('div',{staticClass:"text-block-54"},[_vm._v("© "+_vm._s(_vm.getYear))]),_vm._m(0)]),_vm._m(1),_vm._m(2),_vm._m(3)]),_vm._m(4)])]),_c('section',{staticClass:"mobile-footer"},[_c('div',{staticClass:"div-block-446"},[_c('div',{staticClass:"div-block-447"},[_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"div-block-982",staticStyle:{"text-align":"center"}},[_vm._m(8),_c('div',{staticClass:"text-block-54"},[_vm._v("© "+_vm._s(_vm.getYear))])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legit desk",staticStyle:{"text-align":"center","margin-top":"20px"}},[_c('a',{attrs:{"href":"https://www.legitscript.com/websites/?checker_keywords=joinyara.com","target":"_blank","title":"Verify LegitScript Approval"}},[_c('img',{attrs:{"src":"https://static.legitscript.com/seals/27528976.png","alt":"LegitScript approved","width":"140","height":"120","border":"0"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-980"},[_c('div',{staticClass:"text-block-164"},[_vm._v("Contact")]),_c('a',{staticClass:"link-10",attrs:{"href":"tel:+18885076395"}},[_vm._v("Call Us")]),_c('a',{staticClass:"link-10",attrs:{"href":"mailto:help@joinyara.com?subject=I%20need%20help!"}},[_vm._v("Email Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-980"},[_c('div',{staticClass:"text-block-164"},[_vm._v("Legal")]),_c('a',{staticClass:"link-10",attrs:{"href":"https://joinyara.com/hippa-notice","target":"_blank"}},[_vm._v("HIPAA Notice")]),_c('a',{staticClass:"link-10",attrs:{"href":"https://joinyara.com/privacy-policy-new","target":"_blank"}},[_vm._v("Privacy Policy")]),_c('a',{staticClass:"link-10",attrs:{"href":"https://joinyara.com/returns","target":"_blank"}},[_vm._v("Returns & Refund Policy")]),_c('a',{staticClass:"link-10",attrs:{"href":"https://joinyara.com/terms","target":"_blank"}},[_vm._v("Terms Of Use")]),_c('a',{staticClass:"link-10",attrs:{"href":"https://joinyara.com/ccpa-opt-out","target":"_blank"}},[_vm._v("CCPA Opt-Out")]),_c('a',{staticClass:"link-10",attrs:{"href":"https://joinyara.com/tele-health-consent","target":"_blank"}},[_vm._v("Tele Health Consent")]),_c('a',{staticClass:"link-10",attrs:{"href":"https://joinyara.com/info/safety","target":"_blank"}},[_vm._v("Safety Page")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-980",staticStyle:{"font-size":"8px"}},[_c('div',{staticClass:"text-block-164",staticStyle:{"font-size":"8px"}},[_vm._v("Prescriptions fulfilled by:")]),_c('p',{staticStyle:{"color":"#FFF"}},[_vm._v(" RedRock RX"),_c('br'),_vm._v(" 863 South, 450 West, Suite 201"),_c('br'),_vm._v(" Springville, UT 84663."),_c('br'),_c('br'),_vm._v(" 1-888-507-6395 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legit mobile",staticStyle:{"text-align":"left","margin-top":"20px"}},[_c('a',{attrs:{"href":"https://www.legitscript.com/websites/?checker_keywords=joinyara.com","target":"_blank","title":"Verify LegitScript Approval"}},[_c('img',{attrs:{"src":"https://static.legitscript.com/seals/27528976.png","alt":"LegitScript approved","width":"140","height":"120","border":"0"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-980"},[_c('div',{staticClass:"text-block-164"},[_vm._v("Contact")]),_c('a',{staticClass:"link-10",attrs:{"href":"tel:+18885076395"}},[_vm._v("Call Us")]),_c('a',{staticClass:"link-10",attrs:{"href":"mailto:help@joinyara.com?subject=I%20need%20help!"}},[_vm._v("Email Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-980"},[_c('div',{staticClass:"text-block-164"},[_vm._v("Legal")]),_c('a',{staticClass:"link-10",attrs:{"href":"hippa-notice"}},[_vm._v("HIPAA Notice")]),_c('a',{staticClass:"link-10",attrs:{"href":"privacy-policy-new"}},[_vm._v("Privacy Policy")]),_c('a',{staticClass:"link-10",attrs:{"href":"returns"}},[_vm._v("Returns & Refund Policy")]),_c('a',{staticClass:"link-10",attrs:{"href":"terms"}},[_vm._v("Terms Of Use")]),_c('a',{staticClass:"link-10",attrs:{"href":"ccpa-opt-out"}},[_vm._v("CCPA Opt-Out")]),_c('a',{staticClass:"link-10",attrs:{"href":"tele-health-consent"}},[_vm._v("Tele Health Consent")]),_c('a',{staticClass:"link-10",attrs:{"href":"https://joinyara.com/ambassador"}},[_vm._v("Ambassador Program")]),_c('a',{staticClass:"link-10",attrs:{"href":"https://joinyara.com/info/safety","target":"_blank"}},[_vm._v("Safety Page")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-980",staticStyle:{"font-size":"8px"}},[_c('div',{staticClass:"text-block-164",staticStyle:{"font-size":"8px"}},[_vm._v("Prescriptions fulfilled by:")]),_c('p',{staticStyle:{"color":"#FFF"}},[_vm._v(" RedRock RX"),_c('br'),_vm._v(" 863 South, 450 West, Suite 201"),_c('br'),_vm._v(" Springville, UT 84663."),_c('br'),_c('br'),_vm._v(" 1-888-507-6395 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"w-inline-block",attrs:{"href":"https://www.legitscript.com/websites/?checker_keywords=joinyara.com"}},[_c('img',{staticClass:"image-6",attrs:{"src":"https://static.legitscript.com/seals/27528976.png","loading":"lazy","alt":""}})])
}]

export { render, staticRenderFns }