<template>
    <div>
        <section>
            <div class="div-block-1232">
                <div class="w-layout-blockcontainer container-19 w-container">
                    <a href="#" class="w-inline-block"><img :src="checkoutlogo" loading="lazy" alt="" class="after-qualify-top-logo"></a>
                </div>
                <div class="div-block-1205">
                    <div class="text-block-271">You are all set {{ first_name }}!</div>
                    <p class="paragraph-70">Your Yara care navigator&#x27;s Appointment time:<br><strong> {{ getDate }} </strong><br>‍</p>
                    <div>
                        <div class="div-block-1206"><img src="@/assets/images/icon_Check_1.svg" loading="lazy" alt="" class="image-213">
                            <div class="text-block-272">We will call you on number provided.</div>
                        </div>
                        <div class="div-block-1206"><img src="@/assets/images/icon_Check_1.svg" loading="lazy" alt="" class="image-213">
                            <div class="text-block-272">Call will take 10-15 minutes.</div>
                        </div>
                        <div class="div-block-1206"><img src="@/assets/images/icon_Check_1.svg" loading="lazy" alt="" class="image-213">
                            <div class="text-block-272">Make sure to have questions ready.</div>
                        </div>
                        <div class="div-block-1206"><img src="@/assets/images/icon_Check_1.svg" loading="lazy" alt="" class="image-213">
                            <div class="text-block-272">Please check your email for more info.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="included-space" style="height: 180px;"></div>
        </section>
    </div>
    </template>
    
    <script>
    import moment from 'moment-timezone';
    export default {
        name: 'HelloWorld',
        props: ['baseUrl','checkoutlogo'],
        components: {
            
        },
        data() {
            return {
                selTime: "",
                setDate: '',
            }
        },
        computed:{
            first_name(){
                try{
                    let k = localStorage.getItem("formResponse");
                    if(k){
                        let temp = JSON.parse(k);
                        return temp.first_name;
                    }
                }catch(error){
                    console.log(error);
                    
                }
                return "";
            },
            getDate() {
               try{
                return moment(this.setDate.date).tz(this.setDate.timezone).format('MMMM Do, YYYY @ hh:mm A z')
               }catch(error){
                console.log(error)
                 return "";
               }
               
            }
        },
        created() {
            try{
                this.setDate = JSON.parse(localStorage.getItem("help_slot"));
               }catch(error){
                console.log(error)
               }
            
        }
    }
    </script>
    