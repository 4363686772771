<template>
  <div class="containercal">
    <section class="body-section">
      <img src="@/assets/images/webclip.png" width="86" sizes="86px" style="border-radius: 50%;" alt="" class="image">
      <h3>Confirmed!</h3>
      <p id="scheduler">You are scheduled with Yara.</p>
    </section>
    <section class="description-section">
      <hgroup>
        <h3 id="event">Tele-Med Appointment</h3>
        <div class="icon-text-div">
          <img src="@/assets/images/clock.svg" alt="clock-icon">
          <h4 id="duration">20 min</h4>
        </div><br>
        <div class="icon-text-div">
          <img src="@/assets/images/calendar.svg" alt="calendar-icon">
          <h4 id="event-time-stamp">{{ selTime }}, {{ getDate() }}</h4>
        </div>
      </hgroup>
    </section>
  </div>
</template>
<script>
import listoftimezones from '../Calendar/MTimezones.json'
import moment from 'moment-timezone';
export default {
  props: [],
  data() {
    return {
      aptime: '',
      aptimeend: '',
      selectabetimezone: listoftimezones,
      timezone: '',
      localtimezones: '',
      selTime: '',
      setDate: ''
    }
  },
  methods: {
    getDate() {
      return moment(this.setDate).format("dddd, MMMM Do")
    },
  },
  created() {
    this.selTime = localStorage.getItem("selTime");
    this.setDate = localStorage.getItem("selDate");

  }
}
</script>

<style scoped>
.container {
  display: block;
  height: 85vh;
  text-align: center;
  width: 645px;
  margin: 50px auto;
  overflow: auto;
}

.body-section,
.description-section {
  margin: 50px 100px;
}

.description-section {
  border-top: 1px solid ghostwhite;
  border-bottom: 1px solid ghostwhite;
  padding-top: 25px;
  text-align: initial;
}

@media (max-width: 1250px) {
  .icon-text-div {
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
  }
}

@media (max-width: 645px) {
  .container {
    width: auto;
  }

  .description-section {
    margin: 30px 5%;
  }

  .body-section {
    margin: 30px 2%;
  }
}</style>
