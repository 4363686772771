<template>
<div>
    <section>
        <div class="div-block-1232">
            <div class="w-layout-blockcontainer container-19 w-container">
                <a href="#" class="w-inline-block"><img src="images/yara-placeholder.svg" loading="lazy" alt="" class="image-212"></a>
            </div>
            <div class="div-block-1205">
                <div class="text-block-271">Thanks for the info.</div>
                <p class="paragraph-70">Your information has been sent to your Weight loss doctor. They will reach out if they need anything further. Have a great day.</p>
                <a href="tel:18669551924" class="button-50 w-button">Need Help? Call Now.</a>
            </div>
        </div>
    </section>
    <div style="height:98px;"></div>

</div>
</template>

<script>

export default {
    props: ['baseUrl'],
    components: {
        
    },
    data() {
        return {

        }
    }
}
</script>
