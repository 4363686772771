<template>
    <div>
        <errorMessage v-if="error" :message="errorMessage" @Close="handleClose()"></errorMessage>
        <div v-if="!loading">
            <calendar :baseURL="baseUrl" @SelectedTime="SelectedTime" :visit_type="visit_type" v-if="active_el == 1" @next="moveNext" />
            <thankyou :visit_type="visit_type" v-if="active_el == 3" />
        </div>
        <div v-else>
            <loadingOverlay :text="'Getting dates'" :show="show" />
        </div>
    </div>
    </template>
    
    <script>
    import calendar from './CalDateSelect.vue';
    import thankyou from './thankyouPage.vue';
    import errorMessage from './errorMessage.vue';
    import loadingOverlay from '../common/loadingScreen.vue';
    export default {
        props: ['baseUrl'],
        components: {
            calendar,
            thankyou,
            errorMessage,
            loadingOverlay
        },
        data() {
            return {
                active_el: 1,
                selectedTime: '',
                selectedDate: '',
                people: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: ''
                },
                visit_type: "initial",
                imageaccess: "",
                loading: false,
                pid: 0,
                show: 1,
                userTimeZone: '',
                error: false,
                errorMessage: "Time is no longer available. Please select a new time.",
            }
        },
        methods: {
            AppointMentConfirmed(stattime, endtime) {
                this.appstart = stattime;
                this.appointend = endtime;
                this.active_el++;
            },
            SelectedTime(stime, sdate) {
                this.selectedTime = stime;
                this.selectedDate = sdate;
                if (this.health_id || this.paitient_id) {
                    this.scheduleAppointment();
                } else {
                    this.active_el++;
                }
    
            },
            ScheduleApp(data) {
                this.people = data;
                this.scheduleAppointment();
            },
            handleClose() {
                this.active_el = 1;
                this.error = false;
            },
            scheduleAppointment() {
                this.loading = true;
                let data = {
                    "health_id": this.health_id,
                    "id": this.paitient_id,
                    "slot": JSON.parse(localStorage.getItem("help_slot")),
                };
                window.axios.post(this.baseUrl + '/help-schedule', data).then((response) => {
                    if (response.data.status == 'success') {
                        this.$router.push({
                            name: 'ThanksNavigator',
                        });
                    } else {
                        this.active_el = 1;
                        this.loading = false;
                        this.error = true;
                        this.errorMessage = response.data.message;
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            moveNext() {
                this.active_el++;
            },
    
        },
        created() {
            if (this.$route.query.health_id && this.$route.query.id) {
                this.health_id = this.$route.query.health_id;
                this.paitient_id = this.$route.query.id;
                localStorage.setItem("userState", JSON.stringify(this.$route.query.state))
            } else {
                this.$router.push({
                    name: 'QualifyingQuestion',
                });
            }
        }
    }
    </script>
    