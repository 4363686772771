<template>
    <div>
<section class="deskfooter">
    <div class="div-block-446">
        <div class="div-block-447">
            <div class="div-block-982" style="text-align: center;">
                <a href="index.html" class="w-inline-block"><img :src="footlogo" alt="" class="image-6"></a>
                <div class="text-block-54">© {{getYear}}</div>
                <div class="legit desk" style="text-align: center; margin-top: 20px;"><a href="https://www.legitscript.com/websites/?checker_keywords=joinyara.com" target="_blank" title="Verify LegitScript Approval"><img src="https://static.legitscript.com/seals/27528976.png" alt="LegitScript approved" width="140" height="120" border="0"></a></div>

            </div>
            
            <div class="div-block-980">
                <div class="text-block-164">Contact</div>
                <a href="tel:+18885076395" class="link-10">Call Us</a>
                <a href="mailto:help@joinyara.com?subject=I%20need%20help!" class="link-10">Email Us</a>
            </div>
            <div class="div-block-980">
                <div class="text-block-164">Legal</div>
                <a href="https://joinyara.com/hippa-notice" target="_blank" class="link-10">HIPAA Notice</a>
                <a href="https://joinyara.com/privacy-policy-new" target="_blank" class="link-10">Privacy Policy</a>
                <a href="https://joinyara.com/returns" target="_blank" class="link-10">Returns &amp; Refund Policy</a>
                <a href="https://joinyara.com/terms" target="_blank" class="link-10">Terms Of Use</a>
                <a href="https://joinyara.com/ccpa-opt-out" target="_blank" class="link-10">CCPA Opt-Out</a>
                <a href="https://joinyara.com/tele-health-consent" target="_blank" class="link-10">Tele Health Consent</a>
                <a href="https://joinyara.com/info/safety" target="_blank" class="link-10">Safety Page</a>
                <!--<a href="https://joinyara.com/30daychallengetc.html"  target="_blank" class="link-10">30 Day Challenge </a>
          <a href="https://joinyara.com/terms.html" target="_blank"  class="link-10">Terms &amp; Conditions</a>
          <a href="safety.html" class="link-10">Safety</a>-->
            </div>
            <div class="div-block-980" style="font-size: 8px;">
                <!--<div class="text-block-164">About Us</div>-->
                <!--<a href="returns.html" class="link-10">Yara is a subsidiary of WG Holdings.<br>An American company based in Puerto Rico.<br><br>875 Carr 693, Suite 205<br>Dorado PR 00646<br><br>1-888-507-6395<br> </a><br><br>-->
                <div class="text-block-164" style="font-size: 8px;">Prescriptions fulfilled by:</div>
                <p style="color: #FFF;">

                    RedRock RX<br>
                    863 South, 450 West, Suite 201<br>
                    Springville, UT 84663.<br><br>
                    1-888-507-6395
                </p>

            </div>
        </div>
        <div class="legit mobile" style="text-align: left; margin-top: 20px;"><a href="https://www.legitscript.com/websites/?checker_keywords=joinyara.com" target="_blank" title="Verify LegitScript Approval"><img src="https://static.legitscript.com/seals/27528976.png" alt="LegitScript approved" width="140" height="120" border="0"></a></div>

    </div>
</section>
<section class="mobile-footer">
        <div class="div-block-446">
            <div class="div-block-447">
                <div class="div-block-980">
                    <div class="text-block-164">Contact</div>
                    <a href="tel:+18885076395" class="link-10">Call Us</a>
                    <a href="mailto:help@joinyara.com?subject=I%20need%20help!" class="link-10">Email Us</a>
                </div>
                <div class="div-block-980">
                    <div class="text-block-164">Legal</div>
                    <a href="hippa-notice" class="link-10">HIPAA Notice</a>
                    <a href="privacy-policy-new" class="link-10">Privacy Policy</a>
                    <a href="returns" class="link-10">Returns &amp; Refund Policy</a>
                    <a href="terms" class="link-10">Terms Of Use</a>
                    <a href="ccpa-opt-out" class="link-10">CCPA Opt-Out</a>
                    <a href="tele-health-consent" class="link-10">Tele Health Consent</a>
                    <a href="https://joinyara.com/ambassador" class="link-10">Ambassador Program</a>
                    <a href="https://joinyara.com/info/safety" target="_blank" class="link-10">Safety Page</a>                
                </div>

                <div class="div-block-980" style="font-size:8px;">
                    <div class="text-block-164" style="font-size:8px;">Prescriptions fulfilled by:</div>
                    <p style="color: #FFF;">
                        RedRock RX<br>
                        863 South, 450 West, Suite 201<br>
                        Springville, UT 84663.<br><br>
                        1-888-507-6395
                    </p>
                </div>
                <div class="div-block-982" style="text-align: center;">
                    <a href="https://www.legitscript.com/websites/?checker_keywords=joinyara.com" class="w-inline-block"><img src="https://static.legitscript.com/seals/27528976.png" loading="lazy"
                            alt="" class="image-6"></a>
                    <div class="text-block-54">© {{getYear}}</div>
                </div>

            </div>
            

        </div>
    </section>
    </div>
</template>

<script>
export default {
    name: 'footerComponent',
    props: ['footlogo'],
    data() {
        return {

        }
    },
    computed: {
        getYear() {
            return new Date().getFullYear();
        },
    },

}
</script>

<style scoped>
.legit img {
    max-width: 100px;
    height: auto;
}

.legit.mobile {
    display: none;
}

.legit.desk {
    display: block;
}

.deskfooter {
    display: block;
}

.mobile-footer {
    display: none;
}

@media only screen and (max-width: 767px) {
    .legit.mobile {
        display: block;
    }

    .legit.desk {
        display: none;
    }

    .deskfooter {
        display: none;
    }

    .mobile-footer {
        display: block;
    }

}
</style>
