<template>
    <div>
        <loadingicon v-if="loading" />
        <!--mobile-->
        <div v-if="isMobile">
            <div class="div-block-263 step-2">
                <div class="div-block-265">
                    <div class="div-block-266 new last step-2"><img loading="lazy"
                            :src="checkoutlogo"
                            alt="" class="image-31 summary step-2">
                        <div class="text-block-434">Order Summary</div>
                        <div class="div-block-269 _1 new">
                            <div class="div-block-960 new">
                                <div class="div-block-976">
                                    <div class="div-block-974">
                                        <div class="checkout-product-div step-2"></div>
                                        <div class="div-block-975">
                                            <div class="text-block-161">1</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="text-block-87 step-2">Tirzepatide Program</div>
                                        <div class="text-block-162">Billed Monthly</div>
                                    </div>
                                </div>
                                <div class="text-block-141 new">${{ people.amount - people.discount }}</div>
                                <div class="no-discount-price" v-if="people.discount > 0"><sup>$</sup>{{ people.amount }}
                                </div>
                                <div class="text-block-347 step-2"><sup>$</sup>{{ people.amount - people.discount }}</div>
                            </div>
                        </div>
                        <div class="summary-deets-div">
                            <div class="summary-entry"><img src="@/assets/images/icon_doctor.svg" loading="lazy" alt=""
                                    class="image-278">
                                <div class="item-detail">
                                    <div class="text-block-435">Doctor Support &amp; Monitoring</div>
                                </div>
                                <div class="item-price">
                                    <div class="no-discount-price step-2"><sup>$</sup>99</div>
                                    <div class="text-block-436">Free</div>
                                </div>
                            </div>
                            <div class="summary-entry"><img src="@/assets/images/icon_testing.svg" loading="lazy" alt=""
                                    class="image-278">
                                <div class="item-detail">
                                    <div class="text-block-435">Metabolic testing &amp; blood work.</div>
                                </div>
                                <div class="item-price">
                                    <div class="no-discount-price step-2"><sup>$</sup>75</div>
                                    <div class="text-block-436">Free</div>
                                </div>
                            </div>
                            <div class="summary-entry"><img src="@/assets/images/icon_delivery.svg" loading="lazy"
                                    alt="" class="image-278">
                                <div class="item-detail">
                                    <div class="text-block-435">Express<br>Shipping</div>
                                </div>
                                <div class="item-price">
                                    <div class="no-discount-price step-2"><sup>$</sup>40</div>
                                    <div class="text-block-436">Free</div>
                                </div>
                            </div>
                        </div>
                        <div class="checkout-div step-1">
                            <div class="div-block-1291 step-1"></div>

                            <div class="text-block-434">Complete Payment</div>
                            <div class="w-form">
                                <form class="step-2-left-payment-form new-checkout new-mobile checkout-desk step-1"
                                    @submit.prevent="getCardToken">
                                    <div class="div-block-1292">
                                        <div class="div-block-853 new">
                                            <div class="text-block-80 skinny">Credit Card</div><img width="35"
                                                loading="lazy" alt="" src="@/assets/images/visa.png"
                                                class="image-7"><img width="35" loading="lazy" alt=""
                                                src="@/assets/images/mastercard.png" class="image-7"><img width="35"
                                                loading="lazy" alt="" src="@/assets/images/AmEx.png"
                                                class="image-7"><img width="35" loading="lazy" alt=""
                                                src="@/assets/images/discover.png" class="image-7">
                                        </div>
                                        <div>
                                            <div id="card-element" class="myelement" ref="myElement"></div>
                                        </div>
                                        <div class="div-block-1156"><label class="w-checkbox checkbox-field new"><input
                                                    type="checkbox" name="checkbox-3" id="checkbox-3" checked
                                                    data-name="Checkbox 3" class="w-checkbox-input checkbox-4"
                                                    required><span class="checkbox-label new w-form-label"
                                                    for="checkbox-3">I have read
                                                    and agree to the website <a href="https://joinyara.com/returns"
                                                        target="_blank" class="link-22"><span
                                                            class="text-span-12 new">Refund
                                                            Policy</span></a><span class="text-span-12"> </span>and <a
                                                        href="https://joinyara.com/terms" target="_blank"
                                                        class="link-21"><span class="text-span-12 new">Terms and
                                                            Conditions</span></a></span></label><label
                                                class="w-checkbox checkbox-field new"><input type="checkbox" checked
                                                    name="checkbox-3" id="checkbox-3" data-name="Checkbox 3"
                                                    class="w-checkbox-input checkbox-5" required><span
                                                    class="checkbox-label new w-form-label" for="checkbox-3">I certify
                                                    that I am at least 18 years of age</span></label><label
                                                class="w-checkbox checkbox-field new"><input type="checkbox" checked
                                                    name="checkbox-3" id="checkbox-3" data-name="Checkbox 3"
                                                    class="w-checkbox-input checkbox-6" required><span
                                                    class="checkbox-label new w-form-label" for="checkbox-3">I agree to
                                                    the <a href="https://joinyara.com/terms" target="_blank"
                                                        class="link-23"><span class="text-span-12 new">Terms of
                                                            Use</span></a>, <a
                                                        href="https://joinyara.com/privacy-policy-new" target="_blank"
                                                        class="link-24"><span class="text-span-12 new">Privacy
                                                            Policy</span></a>, <a
                                                        href="https://joinyara.com/hippa-notice" target="_blank"
                                                        class="link-25"><span class="text-span-12 new">HIPPA
                                                            Notice</span></a> and <a
                                                        href="https://joinyara.com/tele-health-consent" target="_blank"
                                                        class="link-26"><span class="text-span-12 new">Telehealth
                                                            Consent</span></a></span></label></div><input type="submit"
                                            data-wait="Please wait..." class="submit-button-3 new w-button"
                                            value="Checkout Now -&gt;">
                                        <div class="checkout-bullets-div">
                                            <div class="checkout-bullet"><img :src="getIconPath('icon_check-copy-3')" 
                                                     loading="lazy" alt=""
                                                    class="image-276">
                                                <div class="text-block-430">Cancel anytime.</div>
                                            </div>
                                            <div class="checkout-bullet"><img
                                                :src="getIconPath('icon_check-copy-3')"  loading="lazy" alt=""
                                                    class="image-276">
                                                <div class="text-block-430">Free online medical consultation.</div>
                                            </div>
                                            <div class="checkout-bullet"><img
                                                    :src="getIconPath('icon_check-copy-3')"  loading="lazy" alt=""
                                                    class="image-276">
                                                <div class="text-block-430">24/7 support via email or phone.</div>
                                            </div>
                                            <div class="checkout-bullet"><img
                                                    :src="getIconPath('icon_check-copy-3')"  loading="lazy" alt=""
                                                    class="image-276">
                                                <div class="text-block-430">100% refund if you are not prescribed.</div>
                                            </div>
                                        </div>
                                        <div class="attention-div">
                                            <div class="text-block-431">In order for us to send you your medication, you
                                                <strong>MUST </strong>complete the brief medical form immediately after
                                                checking out.
                                            </div>
                                        </div>
                                        <div class="secure-div">
                                            <div class="div-block-1369"><img src="@/assets/images/icon_lock.svg"
                                                    loading="lazy" alt="" class="image-277">
                                                <div class="text-block-432">SSL SECURED PAYMENT</div>
                                            </div>
                                            <div class="text-block-433">Your information is protected by 256-bit SSL
                                                encryption</div>
                                        </div>
                                    </div>
                                </form>
                                <div class="w-form-fail" style="display:block" v-if="errorReason">
                                    <div>{{ errorMessage }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--desktop-->
        <div v-else>
            <div class="div-block-263 step-2">
                <div class="div-block-265">
                    <div class="div-block-266 new last step-2"><img loading="lazy"
                            :src="checkoutlogo"
                            alt="" class="image-31 summary step-2">
                        <div class="checkout-div step-1">
                            <div class="div-block-1291 step-1">
                                <div class="text-block-250 checkout step-2">Medical Weight Loss for One Low Monthly
                                    Price.</div>
                                <div class="text-block-429">Finish Payment. Schedule Doc Appointment. Get Meds in 3
                                    days.</div>
                            </div>

                            <div class="w-form">
                                <form @submit.prevent="getCardToken"
                                    class="step-2-left-payment-form new-checkout new-mobile checkout-desk step-1">
                                    <div class="div-block-1292">
                                        <div class="div-block-853 new" style="margin-bottom:15px;">
                                            <div class="text-block-80 skinny">Credit Card</div><img width="35"
                                                loading="lazy" alt="" src="@/assets/images/visa.png"
                                                class="image-7"><img width="35" loading="lazy" alt=""
                                                src="@/assets/images/mastercard.png" class="image-7"><img width="35"
                                                loading="lazy" alt="" src="@/assets/images/AmEx.png"
                                                class="image-7"><img width="35" loading="lazy" alt=""
                                                src="@/assets/images/discover.png" class="image-7">
                                        </div>
                                        <div>
                                            <div id="card-element" class="myelement" ref="myElement"></div>
                                        </div>
                                        <div class="div-block-1156"><label class="w-checkbox checkbox-field new"><input
                                                    type="checkbox" name="checkbox-3" id="checkbox-3"
                                                    data-name="Checkbox 3" class="w-checkbox-input checkbox-4" checked
                                                    required><span class="checkbox-label new w-form-label"
                                                    for="checkbox-3">I have read
                                                    and agree to the website <a href="https://joinyara.com/returns"
                                                        target="_blank" class="link-22"><span
                                                            class="text-span-12 new">Refund
                                                            Policy</span></a><span class="text-span-12"> </span>and <a
                                                        href="https://joinyara.com/terms" target="_blank"
                                                        class="link-21"><span class="text-span-12 new">Terms and
                                                            Conditions</span></a></span></label><label
                                                class="w-checkbox checkbox-field new"><input type="checkbox"
                                                    name="checkbox-3" id="checkbox-3" data-name="Checkbox 3" checked
                                                    class="w-checkbox-input checkbox-5" required><span
                                                    class="checkbox-label new w-form-label" for="checkbox-3">I certify
                                                    that I am at least 18 years of age</span></label><label
                                                class="w-checkbox checkbox-field new"><input type="checkbox" checked
                                                    name="checkbox-3" id="checkbox-3" data-name="Checkbox 3"
                                                    class="w-checkbox-input checkbox-6" required><span
                                                    class="checkbox-label new w-form-label" for="checkbox-3">I agree to
                                                    the <a href="https://joinyara.com/terms" target="_blank"
                                                        class="link-23"><span class="text-span-12 new">Terms of
                                                            Use</span></a>, <a
                                                        href="https://joinyara.com/privacy-policy-new" target="_blank"
                                                        class="link-24"><span class="text-span-12 new">Privacy
                                                            Policy</span></a>, <a
                                                        href="https://joinyara.com/hippa-notice" target="_blank"
                                                        class="link-25"><span class="text-span-12 new">HIPPA
                                                            Notice</span></a> and <a
                                                        href="https://joinyara.com/tele-health-consent" target="_blank"
                                                        class="link-26"><span class="text-span-12 new">Telehealth
                                                            Consent</span></a></span></label></div><input type="submit"
                                            data-wait="Please wait..." class="submit-button-3 new w-button"
                                            value="Checkout Now -&gt;">
                                        <div class="checkout-bullets-div">
                                            <div class="checkout-bullet"><img
                                                    :src="getIconPath('icon_check-copy-3')"  loading="lazy" alt=""
                                                    class="image-276">
                                                <div class="text-block-430">Cancel anytime.</div>
                                            </div>
                                            <div class="checkout-bullet"><img
                                                    :src="getIconPath('icon_check-copy-3')"  loading="lazy" alt=""
                                                    class="image-276">
                                                <div class="text-block-430">Free online medical consultation.</div>
                                            </div>
                                            <div class="checkout-bullet"><img
                                                    :src="getIconPath('icon_check-copy-3')"  loading="lazy" alt=""
                                                    class="image-276">
                                                <div class="text-block-430">24/7 support via email or phone.</div>
                                            </div>
                                            <div class="checkout-bullet"><img
                                                    :src="getIconPath('icon_check-copy-3')"  loading="lazy" alt=""
                                                    class="image-276">
                                                <div class="text-block-430">100% refund if you are not prescribed.</div>
                                            </div>
                                        </div>
                                        <div class="attention-div">
                                            <div class="text-block-431">In order for us to send you your medication, you
                                                <strong>MUST </strong>complete the brief medical form immediately after
                                                checking out.
                                            </div>
                                        </div>
                                        <div class="secure-div">
                                            <div class="div-block-1369"><img src="@/assets/images/icon_lock.svg"
                                                    loading="lazy" alt="" class="image-277">
                                                <div class="text-block-432">SSL SECURED PAYMENT</div>
                                            </div>
                                            <div class="text-block-433">Your information is protected by 256-bit SSL
                                                encryption</div>
                                        </div>
                                    </div>
                                </form>

                                <div class="w-form-fail" style="display:block" v-if="errorReason">
                                    <div>{{ errorMessage }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="div-block-1288 step-2">
                        <div class="div-block-1348">
                            <a href="https://yarahealth.helpscoutdocs.com/" target="_blank"
                                class="link-block-22 w-inline-block">
                                <div class="text-block-406">Need Help</div>
                                <div class="help-div">
                                    <div class="text-block-415">?</div>
                                </div>
                            </a>
                        </div>
                        <div class="summary-desk step-2">
                            <div class="div-block-269 _1 new">
                                <div class="div-block-960 new">
                                    <div class="div-block-976">
                                        <div class="div-block-974">
                                            <div class="checkout-product-div step-2"></div>
                                            <div class="div-block-975">
                                                <div class="text-block-161">1</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="text-block-87 step-2">Semaglutide Program</div>
                                            <div class="text-block-162">Billed Monthly</div>
                                        </div>
                                    </div>
                                    <div class="no-discount-price" v-if="people.discount > 0">
                                        <sup>$</sup>{{ people.amount }}</div>
                                    <div class="text-block-347 step-2"><sup>$</sup>{{ people.amount - people.discount }}
                                    </div>
                                </div>
                            </div>
                            <div class="div-block-269 _1 coupon new"></div>
                            <div class="text-block-90">Monthly Access. <span class="text-span-13">Cancel anytime.
                                </span></div>
                        </div>
                        <div class="step-2-summary">
                            <div class="text-block-434">Order Summary</div>
                            <div class="summary-deets-div">
                                <div class="summary-entry"><img src="@/assets/images/icon_vial.svg" loading="lazy"
                                        alt="" class="image-278">
                                    <div class="item-detail">
                                        <div class="text-block-435">First Month Supply <span
                                                v-if="people.discount > 0">with <sup>$</sup>{{ people.discount }}
                                                discount</span>
                                        </div>
                                        <div class="text-block-437">Month 2 onwards $299</div>
                                    </div>
                                    <div class="item-price">
                                        <div class="no-discount-price step-2" v-if="people.discount > 0"><sup>$</sup>299
                                        </div>
                                        <div class="text-block-436"><sup>$</sup>{{ people.amount - people.discount }}
                                        </div>
                                    </div>
                                </div>
                                <div class="summary-entry"><img src="@/assets/images/icon_doctor.svg" loading="lazy"
                                        alt="" class="image-278">
                                    <div class="item-detail">
                                        <div class="text-block-435">Doctor Support and Monitoring</div>
                                    </div>
                                    <div class="item-price">
                                        <div class="no-discount-price step-2"><sup>$</sup>99</div>
                                        <div class="text-block-436">Free</div>
                                    </div>
                                </div>
                                <div class="summary-entry"><img src="@/assets/images/icon_testing.svg" loading="lazy"
                                        alt="" class="image-278">
                                    <div class="item-detail">
                                        <div class="text-block-435">Metabolic testing and blood work included</div>
                                    </div>
                                    <div class="item-price">
                                        <div class="no-discount-price step-2"><sup>$</sup>75</div>
                                        <div class="text-block-436">Free</div>
                                    </div>
                                </div>
                                <div class="summary-entry"><img src="@/assets/images/icon_delivery.svg" loading="lazy"
                                        alt="" class="image-278">
                                    <div class="item-detail">
                                        <div class="text-block-435">Overnight Shipping</div>
                                    </div>
                                    <div class="item-price">
                                        <div class="no-discount-price step-2"><sup>$</sup>40</div>
                                        <div class="text-block-436">Free</div>
                                    </div>
                                </div>
                                <div class="savings-div"><img src="@/assets/images/party-popper.png" loading="lazy"
                                        alt="" class="image-279">
                                    <div class="text-block-438">You saved <sup>$</sup>{{ 214 +
                                        parseInt(people.discount)}} on your order!</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BasisTheory
} from "@basis-theory/basis-theory-js";
const bt = await new BasisTheory().init("key_prod_us_pub_9drv27VKwcUVoP5BNnnyKZ", {
    elements: true
});
import loading from '../../common/loadingScreen.vue'
export default {
    props: ['baseUrl', 'mainlogo', 'iconSVGColor', 'checkoutlogo'],
    components: {
        'loadingicon': loading,
    },
    data() {
        return {
            errorReason: false,
            errorMessage: '',
            loading: false,
            people: [],
        }
    },
    
    computed: {
        isMobile() {
            return window.innerWidth <= 768;
        },
    },
    mounted() {
        this.getPeopleDetails();
    },
    methods: {
        getIconPath(path) {
            const images = require.context('@/assets/images', false, /\.svg$/);
            return images(`./${path}${this.iconSVGColor}.svg`);
        },
        getPeopleDetails() {
            let queryParams = {};
            const urlParams = new URLSearchParams(window.location.search);
            for (const [key, value] of urlParams) {
                if (key !== 'phone_part')
                    queryParams[key] = value;
            }
            this.people = queryParams;
            if (this.people.step_completed == 1) {
                this.people.amount=299;
                this.createCardElement();
            } else {
                this.$router.push({
                    name: 'checkoutWithBasic'
                });
            }
        },
        getCardToken() {
            this.loading = true;
            this.errorReason = false;
            bt.tokens
                .create({
                    type: "card",
                    data: this.cardElement,
                })
                .then((token) => {
                    this.people.cardToken = token.id;
                    this.people.cardDetails = token.data;
                    this.submitForm();
                })
                .catch((error) => {
                    console.log(error);
                    this.errorReason = true;
                    this.loading = false;
                    this.errorMessage = "Invalid card details"
                });
        },
        submitForm() {
            this.loading = true;
            this.people.program = "Semaglutide";
            window.axios.post(this.baseUrl + '/payments', this.people).then((response) => {
                if (response.data.status == 'success') {
                    this.people.id = response.data.user_id;
                    localStorage.setItem("checkoutForm", JSON.stringify(this.people));
                    this.RegisterAffilate(this.people.id);
                    this.$router.push({
                        name: 'QualificationForm',
                        query: {
                            "id": response.data.user_id,
                            "state": this.people.state,
                        }
                    });
                    this.loading = false;
                } else if (response.data.status == 'existing') {
                    this.errorReason = true;
                    this.errorMessage = "You already have an existing account with us.";
                    this.loading = false;
                } else {
                    this.errorReason = true;
                    this.errorMessage = response.data.message;
                    this.loading = false;
                }
            }).catch((error) => {
                console.log(error);
                this.loading = false;
            });
        },
        RegisterAffilate(id){
            try{
                window.axios.post(this.baseUrl + '/register-affiliate', {
                    "id": id,
                    "affiliate_id": this.$route.query.utm_campaign,
                    "utm_source": this.$route.query.utm_source,
                    "tid": this.$route.query.tid,
                    "discount": this.$route.query.discount,
                    "med": this.people.med,
                    "state": this.$route.query.state,
                    "amount": 299,
                    "pay_terms": 1,
                    "userAgent": navigator.userAgent,
                    "ip_address": JSON.parse(localStorage.getItem("ip_address")),
                    "callcenter_source": null,
                    "forward_by_call": null,
                });
            }catch(error){
                console.log(error)
            }
           
        },
        createCardElement() {
            this.cardElement = bt.createElement('card');
            this.cardElement.mount('#card-element');
        },
    },
    created() {

    }
}
</script>
