<template>
    <div>
        <div class="signup-section">
            <div class="div-block-808">
                <div class="div-block-809"><img width="70" loading="lazy" alt=""
                        src="https://uploads-ssl.webflow.com/657b2233d6a286f5c9f3844c/65a8b55c0b759f07aa5e3058_yara%20placeholder.svg"
                        class="image-139">
                    <div>
                        <div class="text-block-47">Log into your account </div>
                        <div>
                            <div class="w-form">
                                <form id="email-form" name="email-form" @submit.prevent="onSubmit">
                                    <label for="name" class="field-label-5">Email address</label>
                                    <input class="text-field-3 w-input" maxlength="256" v-model="email" name="name"
                                        data-name="Name" type="email" id="email" required>
                                    <label for="name" class="field-label-5">Password</label>
                                    <input class="text-field-3 w-input" maxlength="256" name="name" v-model="password"
                                        data-name="Name" type="password" id="password" required>
                                    <button class="link-block-9 w-inline-block"
                                        style="background-color: transparent; padding-top: 25px;" :disabled="submitted">
                                        <div class="div-block-817" v-if="submitted">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                style="margin: auto; background: none; display: block; shape-rendering: auto;"
                                                width="15px" height="15px" viewBox="0 0 100 100"
                                                preserveAspectRatio="xMidYMid">
                                                <circle cx="50" cy="50" fill="none" stroke="blue" stroke-width="10"
                                                    r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                                    <animateTransform attributeName="transform" type="rotate"
                                                        repeatCount="indefinite" dur="0.53475935828877s"
                                                        values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                                </circle>
                                            </svg>
                                        </div>
                                        <div class="div-block-817" v-else>
                                            <div class="text-block-199">Continue -&gt;</div>
                                        </div>
                                    </button>
                                    <div class="text-block-198" style="padding-top: 35px;">
                                        <router-link to="/forgot-password" class="link-6">Forgot Your
                                            Password?</router-link>
                                    </div>
                                    <div class="text-block-198" style="padding-top: 15px;">Don't have an account?
                                        <router-link to="/" class="link-6">Sign Up</router-link>
                                    </div>
                                </form>
                                <div class="w-form-fail" style="display:block;" v-if="err">
                                <div v-html="errmsg">
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="div-block-810">
                    <div class="text-block-197">Yara Changed My Life! I feel better, look better and finally found a weight loss program that works..and I could stick to<br/><br/>Michelle</div>
                    <div class="text-block-200">Backed by Research From</div>
                    <div class="div-block-1356"><img loading="lazy" src="@/assets/images/SM_Web_vert_LG-white.svg" alt=""
                            class="image-272"><img sizes="(max-width: 479px) 100vw, 160px"
                            srcset="@/assets/images/-medialogo_WebMD-p-500.png 500w, @/assets/images/-medialogo_WebMD-p-800.png 800w, @/assets/images/-medialogo_WebMD.png 1000w"
                            alt="" src="@/assets/images/-medialogo_WebMD.png" loading="lazy" class="image-272"></div>
                    <div class="div-block-1357"><img sizes="(max-width: 479px) 100vw, 193.9765625px"
                            srcset="@/assets/images/mayo-clinic-logo-w-copy-p-500.png 500w, @/assets/images/mayo-clinic-logo-w-copy.png 548w"
                            alt="" src="@/assets/images/mayo-clinic-logo-w-copy.png" loading="lazy" class="image-273"><img
                            sizes="(max-width: 479px) 100vw, 145.453125px"
                            srcset="@/assets/images/-medialogo_HarvardUniversity-p-500.png 500w, @/assets/images/-medialogo_HarvardUniversity-p-800.png 800w, @/assets/images/-medialogo_HarvardUniversity-p-1080.png 1080w, @/assets/images/-medialogo_HarvardUniversity.png 1200w"
                            alt="" src="@/assets/images/-medialogo_HarvardUniversity.png" loading="lazy" class="image-273"></div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: 'LoginPage',
    props: ['baseUrl'],
    data() {
        return {
            email: null,
            password: null,
            submitted: false,
            err: false,
            errmsg: "",
        }
    },
    methods: {
        onSubmit() {
            this.submitted = true;
            this.err = false;
            window.axios.post(this.baseUrl + '/login', {
                password: this.password,
                email: this.email,
            }).then(response => {
                if (response.data.status == 'success') {
                    this.$store.dispatch("doLogin", response.data);
                    this.$router.replace("/home");
                } else {
                    this.submitted = false;
                    this.err = true;
                    this.errmsg = response.data.message;
                }
            }).catch(error => {
                console.log(error)
                this.submitted = false;
                this.err = true;
                this.errmsg = 'Email or password entered is incorrect. Please try again or <router-link to="/forgot-password">reset your password.</router-link>'
            });

        },
    }
}
</script>
<style scoped>
@media only screen and (max-width: 767px) {
    button.link-block-9.w-inline-block {
        padding: 0;
        margin-bottom: 15px;
    }

    .section-8 .text-block-47 {
        margin-bottom: 25px;
    }
}
</style>