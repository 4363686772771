import { render, staticRenderFns } from "./ValPackDiscount.vue?vue&type=template&id=61658126&scoped=true"
import script from "./ValPackDiscount.vue?vue&type=script&lang=js"
export * from "./ValPackDiscount.vue?vue&type=script&lang=js"
import style0 from "./ValPackDiscount.vue?vue&type=style&index=0&id=61658126&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61658126",
  null
  
)

export default component.exports