<template>
<div class="containercal">
    <section class="description-section">
        <button class="back-btn removeSpaces" @click="goBack()"><img class="arrow-icon" src="@/assets/images/arrow.svg" width="86" alt="back-arrow"></button>
        <hgroup>
            <h4 id="scheduler" style="color:black;font-size:18px;"><img src="@/assets/images/webclip.png" width="56"  sizes="56px" alt="" class="imagecal"></h4>
            <h2 id="event">Discovery Meeting</h2>
            <div class="icon-text-div">
                <img src="@/assets/images/clock.svg" alt="clock-icon">
                <h4 id="duration">20 min</h4>
            </div><br>
            <div class="icon-text-div">
                <img src="@/assets/images/calendar.svg" alt="calendar-icon">
                <h4 id="event-time-stamp">{{selTime}}, {{getDate()}}</h4>
            </div>
        </hgroup>
    </section>
    <div class="divider"></div>
    <section id="register-section" class="body-section body-secremovemargin">
        <form @submit.prevent="getformvalues()">
            <h3>Enter Details</h3>
            <label for="name">First Name</label>
            <input type="text" name="First Name" placeholder="First Name" v-model="peopleData.first_name" required>
            <label for="name">Last Name</label>
            <input type="text" name="Last Name" placeholder="Last Name" v-model="peopleData.last_name" required>
            <label for="name">Phone</label>
            <input type="tel" name="phone" placeholder="Phone" v-model="peopleData.phone" required>
            <label for="email">Email</label>
            <input type="email" name="email" placeholder="Email" v-model="peopleData.email" required>
            <button type="submit" :disabled="submitted" id="submit-btn" class="subbutton">
                <span v-if="submitted">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="15px" height="15px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.53475935828877s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                        </circle>
                    </svg></span><span v-else>Schedule</span>
            </button>
        </form>
    </section>

</div>
</template>

<script>
import moment from 'moment-timezone';
export default {
    props: ["cs", "uid", "imageaccess", "selectedTime", "selectedDate", "baseURL", "pid", "people", "defet", "userSettings"],

    data() {
        return {
            endtime: '',
            modeltext: "Please wait while we confirm your appointment.",
            show: false,
            peopleData:null,
            logo: '',
            submitted: false,
            selTime:'',
            setDate:''
        }
    },
    methods: {
        getDate() {
            return moment(this.setDate).format("dddd, MMMM Do")
        },
        goBack() {
            this.$emit("goBack");
        },
        getformvalues() {
            this.submitted = true;
            this.$emit("ScheduleApp", this.peopleData)
        },
    },
    created() {
        this.peopleData = this.people;
        this.selTime = localStorage.getItem("selTime");
        this.setDate = localStorage.getItem("selDate");
    },

}
</script>

<style scoped>
@import "../../assets/css/styles.css";

.subbutton {
    height: 50px;
    background-color: dodgerblue;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    width:400px;
}

.description-section {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.body-section {
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.container {
    max-width: 900px;
    overflow: auto;
}

input {
    width: 400px;
}

button[type="submit"] {
    height: 45px;
    margin-top: 15px;
    padding: 0 20px;
}

button[type="submit"]:focus {
    outline: 0;
    opacity: 0.5;
}

@media (max-width: 1250px) {
    input {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
    }
    .subbutton {
        width: 100%;
    }
    .body-section {
        margin: 30px 100px;
    }

    form {
        text-align: initial;
    }
}

@media (max-width: 645px) {
    .body-section {
        margin: 30px 5%;
    }
    .removeSpaces{
        margin-bottom:2px;
    }
    .body-secremovemargin{
        margin:2px 5%;
    }
    input{
        margin:10px;
    }
}

</style>
