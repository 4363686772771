<template>
    <div>
        <errorMessage v-if="error" :message="errorMessage" @Close="handleClose()"></errorMessage>
        <div v-if="!loading">
            <calendar :baseURL="baseUrl" @SelectedTime="SelectedTime" :visit_type="visit_type" v-if="active_el == 1" @next="moveNext" />
            <userdata :people="people" :selectedTime="selectedTime" :visit_type="visit_type" :selectedDate="selectedDate" :baseUrl="baseUrl" v-if="active_el == 2" @ScheduleApp="ScheduleApp" @goBack="active_el = 1" />
            <thankyou :visit_type="visit_type" v-if="active_el == 3" />
        </div>
        <div v-else>
            <loadingOverlay :text="'Getting dates'" :show="show" />
        </div>
    </div>
    </template>
    
    <script>
    import calendar from './CalDateSelect.vue';
    import userdata from './CalEnterDetails.vue';
    import thankyou from './thankyouPage.vue';
    import errorMessage from './errorMessage.vue';
    import loadingOverlay from '../common/loadingScreen.vue';
    export default {
        props: ['baseUrl'],
        components: {
            calendar,
            userdata,
            thankyou,
            errorMessage,
            loadingOverlay
        },
        data() {
            return {
                active_el: 1,
                selectedTime: '',
                selectedDate: '',
                people: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: ''
                },
                visit_type: "initial",
                imageaccess: "",
                loading: false,
                pid: 0,
                show: 1,
                userTimeZone: '',
                error: false,
                errorMessage: "Time is no longer available. Please select a new time.",
            }
        },
        methods: {
            AppointMentConfirmed(stattime, endtime) {
                this.appstart = stattime;
                this.appointend = endtime;
                this.active_el++;
            },
            SelectedTime(stime, sdate) {
                this.selectedTime = stime;
                this.selectedDate = sdate;
                if (this.health_id || this.paitient_id) {
                    this.scheduleAppointment();
                } else {
                    this.active_el++;
                }
    
            },
            ScheduleApp(data) {
                this.people = data;
                this.scheduleAppointment();
            },
            handleClose() {
                this.active_el = 1;
                this.error = false;
            },
            scheduleAppointment() {
                this.loading = true;
                let data = {
                    "health_id": this.health_id,
                    "paitient_id": this.paitient_id,
                    "visit_type": this.$route.query.visit_type,
                    "slot": JSON.parse(localStorage.getItem("app_slot")),
                    "state": JSON.parse(localStorage.getItem("userState")),
                    ...this.people
                };
                window.axios.post(this.baseUrl + '/scheduling/schedule', data).then((response) => {
                    if (response.data.status == 'success') {
                        this.$router.push({
                            name: 'ThanksAppointment',
                        });
                    } else {
                        this.active_el = 0;
                        this.loading = false;
                        this.error = true;
                        this.errorMessage = response.data.message;
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            moveNext() {
                this.active_el++;
            },
    
        },
        created() {
            if (this.$route.query.health_id && this.$route.query.id && this.$route.query.state) {
                this.health_id = this.$route.query.health_id;
                this.paitient_id = this.$route.query.id;
                this.visit_type = this.$route.query.app_type;
                localStorage.setItem("userState", JSON.stringify(this.$route.query.state))
            } else if (this.$route.query.health_id && this.$route.query.id) {
                this.active_el = 0;
                this.health_id = this.$route.query.health_id;
                this.paitient_id = this.$route.query.id;
                this.visit_type = this.$route.query.app_type;
                window.axios.get(this.baseUrl + '/get-form?id=' + this.$route.query.id, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*"
                    }
                }).then((response) => {
                    this.formResponse = response.data.form.payload;
                    this.formResponse.health_id = this.$route.query.health_id;
                    this.formResponse.id = this.$route.query.id;
                    localStorage.setItem('formResponse', JSON.stringify(this.formResponse))
                    localStorage.setItem("userState", JSON.stringify(this.formResponse.state))
                    this.active_el = 1;
                }).catch((error) => {
                    console.log(error)
                });
            } else {
                this.$router.push({
                    name: 'QualifyingQuestion',
                });
            }
        }
    }
    </script>
    