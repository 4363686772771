<template id="modal-template">
    <div>
      <div  class="modal">
        <h2>Oops!</h2>
        <p>{{message}}</p>
        <button @click="toggleModal" v-if="message!='No active subscription found.'" class="modal-button">Schedule a new time</button>
        <router-link to="/checkout?plan=none&phase=1"   style="width: 100%;" v-else>Click Here To Select Plan</router-link>
      </div>
    </div>
  </template>
 <script>
 export default {
     props: ["message"],
    data() {
      return {
        showModal: false
      };
    },
    methods: {
      toggleModal() {
        this.$emit("Close");
      }
    },
}
</script>
  <style scoped>
 .modal {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 380px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center align the content */
}

.modal-button {
  display: inline-block; /* Use inline-block to center the button */
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
