<template>
<div class="fc fc-media-screen fc-direction-ltr fc-theme-standard">
    <div class="fc-header-toolbar fc-toolbar " style="justify-content: center;">
        <button @click="subtractMonth" class="fc-prev-button fc-button fc-button-primary" style="padding-right:80px;font-weight: bold;" type="button" aria-label="prev"><span class="fc-icon fc-icon-chevron-left"></span></button>
        <div class="fc-toolbar-chunk">
            <h2 class="fc-toolbar-title" style="font-weight: bold;">{{month }} {{year}}</h2>
        </div>
        <button @click="addMonth" class="fc-next-button fc-button fc-button-primary" style="padding-left:80px;font-weight: bold" type="button" aria-label="next"><span class="fc-icon fc-icon-chevron-right"></span></button>
        
    </div>
    <div class="fc-view-harness fc-view-harness-passive">
        <div class="icon-text-div" style="padding-bottom: 25px; display: flex; justify-content: center;">
            <img src="@/assets/images/timezone-globe.svg" alt="clock-icon" width="100px;">
            <select class="timezoneSelect-select" v-model="localtimezonesmy" @change="setTimeZone()">
                <option class="timezoneSelect-options" value="" disabled>Select Your Timezone</option>
                <option class="timezoneSelect-options" v-for="dma in selectabetimezone" :value="dma.type" :key="dma">{{dma.other}}</option>
            </select>
        </div>
        <div class="fc-daygrid fc-dayGridMonth-view fc-view">
            <table class="fc-scrollgrid ">
                <thead>
                    <tr class="fc-scrollgrid-section  fc-scrollgrid-section-sticky">
                        <td>
                            <div class="fc-scroller-harness">
                                <div class="fc-scroller" style="overflow: visible;">
                                    <table class="fc-col-header " style="width: 339px;">
                                        <colgroup></colgroup>
                                        <tbody>
                                            <tr>
                                                <th class="fc-col-header-cell fc-day fc-day-sun">
                                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion ">Sun</a></div>
                                                </th>
                                                <th class="fc-col-header-cell fc-day fc-day-mon">
                                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion ">Mon</a></div>
                                                </th>
                                                <th class="fc-col-header-cell fc-day fc-day-tue">
                                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion ">Tue</a></div>
                                                </th>
                                                <th class="fc-col-header-cell fc-day fc-day-wed">
                                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion ">Wed</a></div>
                                                </th>
                                                <th class="fc-col-header-cell fc-day fc-day-thu">
                                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion ">Thu</a></div>
                                                </th>
                                                <th class="fc-col-header-cell fc-day fc-day-fri">
                                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion ">Fri</a></div>
                                                </th>
                                                <th class="fc-col-header-cell fc-day fc-day-sat">
                                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion ">Sat</a></div>
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="fc-scrollgrid-section ">
                        <td>
                            <div class="fc-scroller-harness">
                                <div class="fc-scroller">
                                    <div class="fc-daygrid-body fc-daygrid-body-unbalanced fc-daygrid-body-natural" style="width: 339px;">
                                        <table class="fc-scrollgrid-sync-table" style="width: 339px;">
                                            <colgroup></colgroup>
                                            <tbody>
                                                <tr v-for="(wee,index) in mydays" :key="index">
                                                    <td class="fc-daygrid-day fc-day fc-day-thu fc-day-past" style="cursor:pointer;" v-for="(da,index) in getwee(wee.week)" :key="index">
                                                        <div class="fc-daygrid-day-frame fc-scrollgrid-sync-inner" :disabled="!checkifbookabke(da)" @click="ClickedMe(da)" >
                                                            <div class="fc-daygrid-day-top" :class="{'is-today': checkDate(da), 'is-bookable' : checkifbookabke(da),'is-makelessvis' : !checkifbookabke(da) , 'is-selected': active_el==da}"><a class="fc-daygrid-day-number"><span>{{da}}</span></a></div>
                                                            <div class="fc-daygrid-day-events"></div>
                                                            <div class="fc-daygrid-day-bg"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>
</template>

<script>
import listoftimezones from './MTimezones.json'
import $ from "jquery";
import moment from 'moment-timezone';
export default {
    props: ['mindate', 'disableddates', 'localtimezones', 'availDates', 'month', 'year'],
    data() {
        return {
            active_el: 0,
            today: moment(),
            selectabetimezone: listoftimezones,
            mindt: new Date(),
            localtimezonesmy: '',
            dateFrom: '',
            dateTo: '',
            dateContext: moment(),
            days: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            firstSatDate: 0,
            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        }
    },
    mounted() {
        this.check();
    },
    computed: {

        mydays() {
            let days = []
            let wek = '';
            let counter = 1;
            if (this.firstDayOfMonth != 0) {
                for (let i = 1; i <= this.firstDayOfMonth; i++) {
                    if (wek == '') {
                        wek = ".";
                    } else {
                        wek = wek + ",.";
                    }
                    counter++;
                }
            }
            for (let i = 1; i <= this.daysInMonth + 6; i++) {
                if (i <= this.daysInMonth) {
                    if (wek == '') {
                        wek = i;
                    } else {
                        wek = wek + "," + i;
                    }
                }
                if (counter == 7) {
                    days.push({
                        week: wek
                    });
                    wek = '';
                    counter = 0;
                }
                counter++;
            }
            return days
        },
        daysInMonth: function () {
            var t = this;
            return t.dateContext.daysInMonth();
        },
        currentDate: function () {
            var t = this;
            return t.dateContext.get('date');
        },
        firstDayOfMonth: function () {
            var t = this;
            var firstDay = moment(t.dateContext).subtract((t.currentDate - 1), 'days');
            return firstDay.weekday();
        },
        initialDate: function () {
            var t = this;
            return t.today.get('date');
        },
        initialMonth: function () {
            var t = this;
            return t.today.format('MMMM');
        },
        initialYear: function () {
            var t = this;
            return t.today.format('Y');
        },
    },
    methods: {
        setTimeZone() {
            this.$emit("setTimezone", this.localtimezonesmy)
        },
        check() {

            var d = new Date(this.mindate);
            var chk = moment(d, 'YYYY/MM/DD');
            var n = chk.format('M');
            var dt = new Date();
            var chk2 = moment(dt, 'YYYY/MM/DD');
            var nt = chk2.format('M');
            if (parseInt(nt) < parseInt(n)) {
                this.addMonth();
            }
        },
        gfg_Run(dates) {
            var date = dates.toJSON().slice(0, 10);
            var nDate = date.slice(8, 10) + '/' +
                date.slice(5, 7) + '/' +
                date.slice(0, 4);
            return nDate;
        },
        isDateAvailable(dateToCheck) {
            const availableDatesSet = new Set(this.availDates);
            return availableDatesSet.has(dateToCheck);
        },
        checkifbookabke(da) {

            var codate = this.year + '-' + moment().month(this.month).format("MM") + '-' + da;
            if (da < 10) {
                codate = this.year + '-' + moment().month(this.month).format("MM") + '-0' + da;
            }

            return this.isDateAvailable(codate)
        },
        checkDate(da) {
            var tdate = moment().date();
            var tmonth = moment(moment().month() + 1, 'MM').format('MMMM');
            var tyear = moment().year();
            if (tmonth == this.month && tyear == this.year && da == tdate) {
                return true;
            } else {
                return false;
            }
        },
        ClickedMe(da) {
            if (this.checkifbookabke(da)) {
                let seldate = this.year + '/' + moment().month(this.month).format("M") + '/' + da;
                this.active_el = da;
                console.log(da)
                this.$emit('getFreeTime', seldate, da, this.year, moment().month(this.month).format("MM"));
            }
        },
        getwee(str) {
            try {
                return str.split(",");
            } catch (error) {
                return [str];
            }
        },
        addMonth() {
            this.$emit("getNewDatesForNext")
        },
        subtractMonth() {
            this.$emit("getNewDatesForPrevious")
        },
        arrangeDates() {
            var classToAdd = "selectable";
            var row;
            var column;
            var currentDay;
            var day;
            $($($($("tr.days").get(row)).find("td").get(column)).addClass(classToAdd + " " + currentDay).html(day));
        },
        isTimeZoneInList(targetTimeZone) {
            return listoftimezones.some(timezone => timezone.type === targetTimeZone);
        }
    },
    created() {
        this.mindt = moment(this.mindate).subtract(1, 'day').toDate();
        if(this.isTimeZoneInList(this.localtimezones)){
            this.localtimezonesmy = this.localtimezones;
        }else{
            this.localtimezonesmy = "America/New_York";
        }
        
    }
}
</script>

<style scoped>
.is-selected {
    font-weight: 700 !important;
    border-radius: 50%;
    color: #fff !important;
    background-color: #3898EC;
    height: 30px;
    width: 30px;
}

select.timezoneSelect-select {
    border: 0;
    font-size: 1rem;
    font-weight: 300;
    color: darkgray;
}

.is-today {
    display: block;
}
</style>
