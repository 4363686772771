import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import CheckoutForm from '@/components/Checkout/FlowOne/checkoutUpdated.vue';
import CheckoutStep2 from '@/components/Checkout/FlowOne/checkoutStep2.vue';
import checkoutUpdatedAbu from '@/components/Checkout/FlowOne/checkoutUpdatedAbu.vue';

import checkoutWithBasic from '@/components/Forms/FlowTwo/checkoutWithBasic.vue';
import checkoutWithBasicStep2 from '@/components/Checkout/FlowTwo/checkoutWithBasicStep2.vue';
import QualificationForm from '@/components/Forms/FlowTwo/QualificationForm.vue';

import FullQuestionStageTwo from '@/components/Forms/FlowOne/FullQuestionStageTwo.vue'
//import QualifyingQuestion22 from '@/components/Forms/FlowOne/QualifyingQuestion.vue';

import QualifyingQuestion from '@/components/Forms/FlowOne/QualifyingQuestion2.vue';

import ThanksAppointment from '@/components/Forms/FlowOne/ThankYouAppointment.vue';
import RefillQuestions from '@/components/Forms/FlowOne/RefillQuestions.vue'
import ThankYou from '@/components/Forms/FlowOne/ThankYou.vue';
import CongratulationsPage from '@/components/Forms/FlowOne/CongratulationsPage.vue';
import AfterCheckout from '@/components/Forms/FlowOne/AfterCheckout.vue';

import Calendar from '@/components/Calendar/CalanderMain.vue';

import ThanksNavigator from '@/components/Forms/FlowOne/ThankYouNavigator.vue';

import HelpCalendar from '@/components/HelpCalendar/CalanderMain.vue';

//import QuestionForm1 from '@/components/Webpages/backupForms/QuestionForm1.vue';
import HomePage from '@/components/Dashboard/homePage.vue';
import LoginPage from '@/components/Auth/LoginPage.vue';
import ForgotPassword from '@/components/Auth/ForgotPassword.vue';
import ResetPassword from '@/components/Auth/ResetPassword.vue';

import DiscountPage100 from '@/components/DiscountPages/DiscountPage.vue';
import DiscountPagePreSell from '@/components/DiscountPages/DiscountPagePreSell.vue';

import ValPackDiscount from '@/components/DiscountPages/ValPackDiscount.vue';

import DiscountPage100Ray from '@/components/DiscountPages/DiscountPage100Ray.vue';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  hash: false,
  routes: [
    {
      path: '/',
      name: 'QualifyingQuestion',
      component: QualifyingQuestion,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/glp1',
      name: 'QualifyingGLP',
      component: QualifyingQuestion,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/sema50',
      name: 'Sema50',
      component: QualifyingQuestion,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/sema100',
      name: 'Sema100',
      component: QualifyingQuestion,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/wellness-program',
      name: 'DirectToAppointment',
      component: QualifyingQuestion,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/congratulations',
      name: 'CongratulationsPage',
      component: CongratulationsPage,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/thanks-you',
      name: 'AfterCheckout',
      component: AfterCheckout,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/get-to-know',
      name: 'FullQuestionStageTwo',
      component: FullQuestionStageTwo,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/get-qualified',
      name: 'QualificationForm',
      component: QualificationForm,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/re-visit',
      name: 'RefillQuestions',
      component: RefillQuestions,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/re-thanks',
      name: 'ThankYouRevisit',
      component: ThankYou,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/get-started',
      name: 'checkoutWithBasic',
      component: checkoutWithBasic,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/payment',
      name: 'checkoutWithBasicStep2',
      component: checkoutWithBasicStep2,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/checkout',
      name: 'CheckoutForm',
      component: CheckoutForm,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/checkoutbu',
      name: 'checkoutUpdatedAbu',
      component: checkoutUpdatedAbu,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/checkout-step2',
      name: 'CheckoutStep2',
      component: CheckoutStep2,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/schedule',
      name: 'Calendar',
      component: Calendar,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/help-appointment',
      name: 'HelpCalendar',
      component: HelpCalendar,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/help-thanks',
      name: 'ThanksNavigator',
      component: ThanksNavigator,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/schedule-confirm',
      name: 'ThanksAppointment',
      component: ThanksAppointment,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/home',
      name: 'HomePage',
      component: HomePage,
      meta: {
        requiresAuth: true,
        guest: false
      }
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/password-reset/:token',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/discount-50',
      name: 'DiscountPage50',
      component: DiscountPage100,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/discount-100',
      name: 'DiscountPage100',
      component: DiscountPage100,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/discount50pre',
      name: 'DiscountPagePreSell',
      component: DiscountPagePreSell,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/easy50pre',
      name: 'DiscountPageSkinny',
      component: DiscountPagePreSell,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/valpak50',
      name: 'ValPackDiscount50',
      component: ValPackDiscount,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/valpak100',
      name: 'ValPackDiscount100',
      component: ValPackDiscount,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/easy100pre',
      name: 'DiscountPage100Ray',
      component: DiscountPage100Ray,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/discount100pre',
      name: 'DiscountPage100S',
      component: DiscountPage100Ray,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/sema100pre',
      name: 'sema100pre',
      component: DiscountPage100Ray,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/*',
      name: '404',
      component: QualifyingQuestion,
      meta: {
        guest: true
      }
    },
  ],

})

router.beforeEach((to, from, next) => {
  const currentParams = from.query;
  const targetParams = to.query;
  const mergedParams = { ...currentParams, ...targetParams };
  const paramsDiffer = JSON.stringify(targetParams) !== JSON.stringify(mergedParams);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.authToken == null) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else if (to.name == "LoginPage" && store.getters.authToken !== null) {
    next({
      path: '/home'
    })
  } else if (paramsDiffer) {
    next({
      ...to,
      query: mergedParams, // Include merged parameters
    });
  } else {
    next();
  }
})
export default router;
