<template>
    <div class="show">
        
        <loadingicon v-if="loading" />
        <div class="new-summary" v-if="!isAbove480">
            <div class="header-div"><img :src="checkoutlogo" loading="lazy" alt="" class="image-242">
                <a href="https://yarahealth.helpscoutdocs.com/" target="_blank" class="link-block-18 w-inline-block">
                    <div class="text-block-342">Need Help</div><img src="@/assets/images/icon_faq.svg" loading="lazy"
                        alt="" class="image-244">
                </a>
            </div>
            <div class="dropdown-sum">
                <div class="order-summary-div">
                    <div data-hover="false" data-delay="0" class="order-dropdown w-dropdown"
                        v-bind:class="{ 'zindexcls': openMob }">
                        <div class="dropdown-sum-toggle w-dropdown-toggle" v-bind:class="{ 'w--open': openMob }"
                            @click.prevent="openMob = !openMob">
                            <div class="icon-19 w-icon-dropdown-toggle" v-bind:class="{ 'mkeup': openMob }"></div>
                            <div class="div-block-1282">
                                <div class="text-block-327">{{ people.med }} Monthly</div>
                                <div class="text-block-328">${{ people.amount }}</div>
                            </div>
                            <div class="div-block-1283">
                                <div class="text-block-333">Shipping</div>
                                <div class="text-block-333">FREE</div>
                            </div>
                        </div>
                        <nav class="dropdown-summary w-dropdown-list" v-bind:class="{ 'w--open': openMob }">
                            <div class="div-block-269 _1 new summary-drop">
                                <div class="div-block-960 new summary-drop">
                                    <div class="div-block-976">
                                        <div class="div-block-974">
                                            <img src="@/assets/images/cart-image_semaglutide.jpg" loading="lazy"
                                                class="image-36"
                                                sizes="(max-width: 479px) 10px, (max-width: 991px) 8vw, 80px"
                                                srcset="@/assets/images/cartimage_SemaVial-p-500.jpg 500w, images/cartimage_SemaVial-p-800.jpg 800w, images/cart-image_semaglutide.jpg 1000w"
                                                alt="" v-if="people.med == 'Semaglutide'">
                                            <img v-else src="@/assets/images/cart-image_TrizepatideVial.jpg"
                                                loading="lazy" sizes="100vw"
                                                srcset="@/assets/images/cart-image_TrizepatideVial-p-500.jpg 500w, @/assets/images/cart-image_TrizepatideVial-p-800.jpg 800w, @/assets/images/cart-image_TrizepatideVial.jpg 1000w"
                                                alt="" class="image-36">
                                            <div class="div-block-975">
                                                <div class="text-block-161">1</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="text-block-87">{{ people.med }} Program</div>
                                            <div class="text-block-162">Billed Monthly</div>
                                        </div>
                                    </div>
                                    <div class="text-block-329">${{ people.amount }}</div>
                                </div>
                            </div>
                            <div class="form-block-9 drop-sum w-form" v-if="showCoupon">
                                <form id="email-form-4" @submit.prevent="checkCoupon()">
                                    <div class="div-block-977"><input class="text-field-5 coupon new drop-sum w-input"
                                            v-model="coupon" maxlength="256" name="coupon-code"
                                            v-bind:class="{ 'invalid': !valid_coupon }" data-name="coupon code"
                                            placeholder="Coupon Code" type="text" id="coupon-code" required>
                                        <button class="button-15 new w-button">Apply</button>
                                    </div>
                                </form>
                                <div class="w-form-done">
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div class="w-form-fail">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                            <div class="div-block-269 _2 new drop-sum">
                                <div class="div-block-962 new">
                                    <div class="text-block-331">Subtotal</div>
                                    <div class="text-block-332">${{ people.amount }}</div>
                                </div>
                                <div class="div-block-962 new" v-if="discount > 0">
                                    <div class="text-block-331">Discount</div>
                                    <div class="text-block-332">-${{ discount }}</div>
                                </div>
                                <div class="div-block-962 new">
                                    <div class="text-block-331">Shipping</div>
                                    <div class="text-block-332">FREE</div>
                                </div>
                                
                            </div>
                            <div class="div-block-269 _2 new drop-sum total">
                                <div class="div-block-962 new drop-sub">
                                    <div class="text-block-88 _2 after"><strong>Total</strong></div>
                                    <div class="text-block-163"><strong class="bold-text-3">${{ people.amount - discount
                                            }}</strong>
                                    </div>
                                </div>
                                <div class="text-block-341">Subscription fee may increase if prescribed a higher
                                    dose of medication.</div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div class="text-block-336">(Tap above to access full order details)</div>
            </div>
            <div class="express-main-div" v-if="isApplePaySupported">
                <div class="express-box">
                    <div class="text-block-334">Express Checkout</div>
                    <a href="#" class="link-block-17 w-inline-block">
                        <img src="@/assets/images/Apple_Pay_logo-w.svg" loading="lazy" alt="" class="image-243"></a>
                </div>
            </div>
            <div class="div-block-1284" v-bind:class="{ 'remove-gap': !isApplePaySupported }">
                <div class="text-block-335" v-if="isApplePaySupported">OR CONTINUE BELOW TO PAY WITH CREDIT CARD
                </div>
                <div class="w-form">
                    <form id="email-form-2" class="step-2-left-payment-form new-checkout new-mobile _2"
                        @submit.prevent="getCardToken">
                        <label for="Address" class="field-label-4 new">Shipping Address</label>
                        <vue-google-autocomplete id="google-autocomplete" placeholder="Start typing address..."
                            v-on:placechanged="getAddressData" types="geocode" country="us"></vue-google-autocomplete>
                        <div class="div-block-853 new">
                            <div class="text-block-80"><strong>Credit Card</strong></div><img
                                src="@/assets/images/visa.png" loading="lazy" width="35" alt="" class="image-7"><img
                                src="@/assets/images/mastercard.png" loading="lazy" width="35" alt=""
                                class="image-7"><img src="@/assets/images/AmEx.png" loading="lazy" width="35" alt=""
                                class="image-7"><img src="@/assets/images/discover.png" loading="lazy" width="35" alt=""
                                class="image-7">
                        </div>
                        <div><label for="Card-number" class="field-label-4">Card Number</label>
                            <div id="card-element" class="myelement" ref="myElement"></div>
                        </div>
                        <div class="div-block-1156">
                            <label class="w-checkbox checkbox-field new">
                                <input type="checkbox" id="checkbox-3" name="checkbox-3" data-name="Checkbox 3"
                                    class="w-checkbox-input" checked required>
                                <span class="checkbox-label new w-form-label" for="checkbox-3">I have read and agree
                                    to the website <a href="https://joinyara.com/returns" target="_blank" class="link-22"><span
                                            class="text-span-12 new">Refund Policy</span></a><span class="text-span-12">
                                    </span>and <a href="https://joinyara.com/terms" target="_blank" class="link-21"><span
                                            class="text-span-12 new">Terms and
                                            Conditions</span></a></span></label><label
                                class="w-checkbox checkbox-field new"><input type="checkbox" checked id="checkbox-3"
                                    name="checkbox-3" data-name="Checkbox 3" class="w-checkbox-input" required><span
                                    class="checkbox-label new w-form-label" for="checkbox-3">I certify that I am at
                                    least 18 years of age</span></label><label
                                class="w-checkbox checkbox-field new"><input type="checkbox" checked id="checkbox-3"
                                    name="checkbox-3" data-name="Checkbox 3" class="w-checkbox-input" required><span
                                    class="checkbox-label new w-form-label" for="checkbox-3">I agree to the <a
                                        href="https://joinyara.com/terms" target="_blank" class="link-23"><span class="text-span-12 new">Terms of
                                            Use</span></a>, <a href="https://joinyara.com/privacy-policy-new" target="_blank" class="link-24"><span
                                            class="text-span-12 new">Privacy Policy</span></a>, <a
                                        href="https://joinyara.com/hippa-notice" class="link-25"><span class="text-span-12 new">HIPPA
                                            Notice</span></a> and <a href="https://joinyara.com/tele-health-consent" target="_blank"
                                        class="link-26"><span class="text-span-12 new">Telehealth
                                            Consent</span></a></span></label>
                        </div><input type="submit" data-wait="Please wait..." class="submit-button-3 new w-button"
                            value="Next Step -&gt;">
                        <div class="div-block-1154">
                            <div class="text-block-252">If you do not qualify after your teledoc appointment then
                                you will be given a full refund.</div>
                        </div>
                    </form>
                    <div class="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="w-form-fail" style="display: block;" v-if="errorReason">
                        <div>Invalid card details.</div>
                    </div>
                    <div class="w-form-fail" style="display: block;" v-if="addErr">
                        <div>Please enter your address</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="div-block-263" v-else>
            <div class="div-block-265">
                <div class="div-block-266 new last"><img :src="checkoutlogo" loading="lazy" alt=""
                        class="image-31 summary">
                    <div class="step-4">
                        <div class="div-block-1291">
                            <h1 class="heading-8 _1 b checkout">Everything included. One low monthly price.</h1>
                            <div class="text-block-250 checkout">Start your weight loss journey this week without
                                leaving your home.</div>
                        </div>
                        <div class="w-form">
                            <form id="email-form-2" @submit.prevent="getCardToken"
                                class="step-2-left-payment-form new-checkout new-mobile checkout-desk">
                                
                                <div class="div-block-1290" v-if="isApplePaySupported">
                                    <div class="text-block-349">EXPRESS CHECKOUT</div>
                                    <a href="#" class="link-block-13 w-inline-block">
                                        <div class="div-block-1155"><img src="placeholder.svg" loading="lazy"
                                                alt=""><img src="@/assets/images/Apple_Pay_logo-w.svg" loading="lazy"
                                                alt="" class="image-201"></div>
                                    </a>
                                </div>
                                <div class="text-block-350" v-if="isApplePaySupported">OR CONTINUE BELOW TO PAY WITH
                                    CREDIT CARD</div>
                                <div class="div-block-1292"><label for="email-5" class="field-label-4">Shipping
                                        Address</label>
                                    <vue-google-autocomplete id="google-autocomplete"
                                        placeholder="Start typing address..." v-on:placechanged="getAddressData"
                                        types="geocode" country="us"></vue-google-autocomplete>
                                    <div class="div-block-853 new">
                                        <div class="text-block-80"><strong>Credit Card</strong></div><img
                                            src="@/assets/images/visa.png" loading="lazy" width="35" alt=""
                                            class="image-7"><img src="@/assets/images/mastercard.png" loading="lazy"
                                            width="35" alt="" class="image-7"><img src="@/assets/images/AmEx.png"
                                            loading="lazy" width="35" alt="" class="image-7"><img
                                            src="@/assets/images/discover.png" loading="lazy" width="35" alt=""
                                            class="image-7">
                                    </div>
                                    <div>
                                        <label for="email-3" class="field-label-4 new">Card Number</label>
                                        <div id="card-element" class="myelement" ref="myElement"></div>
                                    </div>
                                    <div class="div-block-1156"><label class="w-checkbox checkbox-field new"><input
                                                type="checkbox" id="checkbox-3" name="checkbox-3" data-name="Checkbox 3"
                                                class="w-checkbox-input" checked required><span
                                                class="checkbox-label new w-form-label" for="checkbox-3">I have read
                                                and agree to the website <a href="https://joinyara.com/returns" target="_blank" class="link-22"><span
                                                        class="text-span-12 new">Refund
                                                        Policy</span></a><span class="text-span-12"> </span>and <a
                                                    href="https://joinyara.com/terms" target="_blank" class="link-21"><span
                                                        class="text-span-12 new">Terms and
                                                        Conditions</span></a></span></label><label
                                            class="w-checkbox checkbox-field new"><input type="checkbox" checked id="checkbox-3"
                                                name="checkbox-3" data-name="Checkbox 3" class="w-checkbox-input"
                                                required><span class="checkbox-label new w-form-label"
                                                for="checkbox-3">I certify
                                                that I am at least 18 years of age</span></label><label
                                            class="w-checkbox checkbox-field new"><input type="checkbox" checked id="checkbox-3"
                                                name="checkbox-3" data-name="Checkbox 3" class="w-checkbox-input"
                                                required><span class="checkbox-label new w-form-label"
                                                for="checkbox-3">I agree to
                                                the <a href="https://joinyara.com/terms" target="_blank" class="link-23"><span
                                                        class="text-span-12 new">Terms of Use</span></a>, <a
                                                    href="https://joinyara.com/privacy-policy-new" target="_blank" class="link-24"><span
                                                        class="text-span-12 new">Privacy Policy</span></a>, <a
                                                    href="https://joinyara.com/hippa-notice"  target="_blank" class="link-25"><span
                                                        class="text-span-12 new">HIPPA Notice</span></a> and <a
                                                    href="https://joinyara.com/tele-health-consent" target="_blank" class="link-26"><span
                                                        class="text-span-12 new">Telehealth
                                                        Consent</span></a></span></label></div><input type="submit"
                                        data-wait="Please wait..." class="submit-button-3 new w-button"
                                        value="Next Step -&gt;">
                                </div>
                                <div class="div-block-1154">
                                    <div class="text-block-252">If you do not qualify after your teledoc appointment
                                        then you will be given a full refund.</div>
                                </div>
                            </form>

                            <div class="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div class="w-form-fail" style="display: block;" v-if="errorReason">
                                <div>Invalid card details.</div>
                            </div>
                            <div class="w-form-fail" style="display: block;" v-if="addErr">
                                <div>Please enter your address</div>
                            </div>
                        </div>

                        <!--call center-->
                        <div class="w-form" v-if="showCallCenter">
                            <div class="div-block-1292">
                                <a href="#" @click="sendLinkSMS($event)" data-wait="Please wait..." class="submit-button-3 new w-button">Send Checkout Link </a>
                            </div>
                            <div style="height:30px;"></div>
                            <div class="div-block-1292">
                                <a href="#" @click="checkSubscriptionStatus($event)" data-wait="Please wait..." class="submit-button-3 new w-button">Check Status
                                </a>
                            </div>
                        </div>
                        <!--call center -->
                    </div>
                </div>
                <div class="div-block-1288 showdesk" v-bind:class="{ 'ifg_right': isIfg }">
                    <div class="div-block-1293">
                        <a href="https://yarahealth.helpscoutdocs.com/" target="_blank" class="link-block-20 w-inline-block">
                            <div class="text-block-351">Need Help</div><img src="@/assets/images/icon_faq.svg"
                                loading="lazy" alt="" class="image-246">
                        </a>
                    </div>
                    <div class="summary-desk step-2">
                        <div class="div-block-269 _1 new">
                            <div class="div-block-960 new">
                                <div class="div-block-976">
                                    <div class="div-block-974">
                                        <img src="@/assets/images/cart-image_semaglutide.jpg" loading="lazy"
                                            class="image-36"
                                            sizes="(max-width: 479px) 10px, (max-width: 991px) 8vw, 80px"
                                            srcset="@/assets/images/cartimage_SemaVial-p-500.jpg 500w, images/cartimage_SemaVial-p-800.jpg 800w, images/cart-image_semaglutide.jpg 1000w"
                                            alt="" v-if="people.med == 'Semaglutide'">
                                        <img src="@/assets/images/cart-image_TrizepatideVial.jpg" loading="lazy"
                                            sizes="(max-width: 479px) 10px, (max-width: 991px) 8vw, 80px"
                                            srcset="@/assets/images/cart-image_TrizepatideVial-p-500.jpg 500w, images/cart-image_TrizepatideVial-p-800.jpg 800w, images/cart-image_TrizepatideVial.jpg 1000w"
                                            alt="" class="image-36" v-else>
                                        <div class="div-block-975">
                                            <div class="text-block-161">1</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="text-block-87">{{ people.med }} Program</div>
                                        <div class="text-block-162">Billed <span
                                                v-if="people.pay_terms == 1">Monthly</span><span
                                                v-if="people.pay_terms == 3">Three Month</span><span
                                                v-if="people.pay_terms == 6">Six Month</span></div>
                                    </div>
                                </div>
                                <div class="text-block-141 new">${{ people.amount }}</div>
                                <div class="text-block-347">${{ people.amount }}</div>
                            </div>
                        </div>
                        <div class="div-block-269 _1 coupon new" :key="mkey">
                            <div class="form-block-9 w-form" v-if="showCoupon">
                                <form id="email-form-4" name="email-form-4" @submit.prevent="checkCoupon()">
                                    

                                    <div class="div-block-977 checkout">
                                        <input v-model="coupon" v-bind:class="{ 'invalid': !valid_coupon }"
                                            class="text-field-5 coupon new w-input" maxlength="256"
                                            placeholder="Coupon Code" type="text" id="field-3" required>
                                        <button class="button-15 new w-button">Apply</button>
                                        <br />
                                        
                                    </div>

                                </form>
                                <div class="w-form-done">
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div class="w-form-fail" style="display: block;" v-if="!valid_coupon">
                                            <div>Coupon Expired</div>
                                        </div>
                            </div>
                            <div class="div-block-269 _2 new">
                                <div class="div-block-962 new">
                                    <div class="text-block-88 _2 after"><strong>Subtotal</strong></div>
                                    <div class="text-block-163">${{ people.amount }}</div>
                                </div>
                                <div class="div-block-962 new" v-if="discount > 0">
                                    <div class="text-block-88 _2 after"><strong>Discount</strong></div>
                                    <div class="text-block-163">- ${{ discount }}</div>
                                </div>
                                <div class="div-block-962 new">
                                    <div class="text-block-88 _2 after"><strong>Shipping</strong></div>
                                    <div class="text-block-163">FREE</div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="div-block-1289">
                            <div class="text-block-348">Total</div>
                            <div class="text-block-348">${{ people.amount - discount }}</div>
                        </div>
                        <div class="text-block-90">Monthly Access. <span class="text-span-13">Cancel anytime.
                            </span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BasisTheory
} from "@basis-theory/basis-theory-js";
const bt = await new BasisTheory().init("key_prod_us_pub_9drv27VKwcUVoP5BNnnyKZ", {
    elements: true
});
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import loading from '../../common/loadingScreen.vue'
export default {
    name: 'cardCheckout',
    props: ['baseUrl', 'mainlogo', 'checkoutlogo','iconSVGColor'],
    components: {
        'loadingicon': loading,
        VueGoogleAutocomplete
    },
    data() {
        return {
            el: 1,
            selectedMed: '',
            isIfg: false,
            isAbove480: window.innerWidth > 480,
            openMob: false,
            errorReason: false,
            interval: null,
            cardElement: null,
            isApplePaySupported: false,
            loading: false,
            session: '',
            discount: 0,
            hide: 0,
            monthly: 299,
            semathree: 897,
            semasix: 1554,
            coupon: '',
            valid_coupon: true,
            initiallyOn: '',
            tirOpen: false,
            semaOpen: false,
            addErr: false,
            mkey: 0,
            selected: "none",
            couponapplied: false,
            semaselected: true,
            showCoupon: true,
            showCall: false,
            people: {
                paitient_id: '',
                id: '',
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                phone_first: '',
                phone_second: '',
                phone_third: '',
                address_line_1: '',
                address_line_2: '',
                city: "",
                state: "",
                zip: '',
                cardToken: '',
                cardDetails: '',
                amount: 0,
                med: '',
                coupon_map_to: '',
                health_id: '',
                full_address: '',
                utm_campaign: '',
                phase: 1,
                pay_terms: 1,
                callcenter_source: '',
                forward_by_call:''
            }
        }
    },
    computed: {
        showCallCenter(){
            const callcenter_source = this.$route.query.callcenter_source;
            if (callcenter_source) {
                return true;
            } else {
                return false;
            }
        },
        componentsToShow() {
            const plan = this.$route.query.plan;
            const isMobile = window.innerWidth <= 768; // Define your mobile breakpoint

            if (plan === 'none' || plan === 'Semaglutide' || plan == 'Wegovy' || plan == 'Ozempic') {
                return isMobile ? ['Com1', 'mob3'] : ['Com1', 'Com2'];
            } else {
                return ['Com2'];
            }
        }
    },
    watch: {
        el() {
            this.scrolltoTop();
        }
    },
    mounted() {
        this.createCardElement();
        this.getPaitient();
        this.checkApplePaySupport();
        this.checkIFG();
    },
    methods: {
        checkIFG(){
            if(this.iconSVGColor =="_ifg"){
                this.isIfg = true;
            }
        },
        checkSubscriptionStatus(event){
            event.preventDefault();
            window.axios.get(this.baseUrl + '/check-subs/'+this.people.id).then((response) => {
                console.log(response);
                if(response.data.data=="success"){
                    alert("User has completed checkout.")
                }else{
                    alert("Waitiing on user to complete checkout.")
                }
            }).catch((error) => {
                console.log(error);
            });

        },
        sendLinkSMS(event){
            event.preventDefault();
            const currentUrl = new URL(window.location.href);
            const paramToRemove = 'callcenter_source';
            const paramToAdd = 'forward_by_call';
            const paramValue = currentUrl.searchParams.get(paramToRemove);
            currentUrl.searchParams.delete(paramToRemove);
            if (paramValue !== null) {
                currentUrl.searchParams.set(paramToAdd, paramValue);
            }
            const updatedUrl = currentUrl.toString();
            let message ="Hi it's Yara! Please click this custom link to enter your card information and finish sign up : " + updatedUrl;
            window.axios.post(this.baseUrl + '/send-sms-callcenter', {
                id: this.people.id,
                sms_type: "call",
                message: message
            }).then((response) => {
                console.log(response);
                alert("SMS sent")
            }).catch((error) => {
                console.log(error);
            });
        },
        checkCoupon() {
            if (this.couponapplied) {
                return false;
            }
            this.valid_coupon = true;
            window.axios.post(this.baseUrl + '/validate-coupon', {
                coupon: this.coupon
            }).then((response) => {
                if (response.data.status == 'success') {
                    this.valid_coupon = true;
                    this.couponapplied = true;
                    if (response.data.c_type == 1) {
                        this.discount = response.data.c_val;
                    } else {
                        this.discount = Math.round((this.people.amount * response.data.c_val) / 100);
                    }
                } else {
                    this.valid_coupon = false;
                }
                this.mkey++;
            }).catch((error) => {
                console.log(error);
                this.loading = false;
            });
        },
        isIpad() {
            const ua = navigator.userAgent;
            const isIpadMini = /iPad/.test(ua) && (window.devicePixelRatio === 2 || window.devicePixelRatio === 3) && (window.innerWidth === 768 || window.innerHeight === 768);
            const isIpadAir = /iPad/.test(ua) && (window.devicePixelRatio === 2 || window.devicePixelRatio === 3) && (window.innerWidth === 810 || window.innerHeight === 810);
            return isIpadMini || isIpadAir;
        },
        setPricing(val) {
            if (val == 3) {
                this.people.amount = this.semathree;
                this.people.pay_terms = 3
            } else if (val == 6) {
                this.people.amount = this.semasix;
                this.people.pay_terms = 6
            } else {
                this.people.amount = this.monthly;
                this.people.pay_terms = 1
            }
        },
        selectMed(val) {
            this.people.med = val;
            if (val == 'Semaglutide') {
                this.selected = true;
            } else {
                this.selected = false;
            }
            this.getAmountToPay();
        },
        makeSameOpen() {
            this.semaOpen = true;
        },
        scrolltoTop() {
            setTimeout(function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        },

        checkApplePaySupport() {
            this.isApplePaySupported = false;
            if (window.ApplePaySession) {
                var merchantIdentifier = 'merchant.com.joinyara';
                var promise = window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
                promise.then((canMakePayments) => {
                    if (canMakePayments) {
                        this.isApplePaySupported = true;
                    }
                }).catch(error => {
                    console.error('Error checking Apple Pay support:', error);
                });
            } else {
                console.warn('Apple Pay is not supported');
            }
        },
        getPaitient() {

            if (!this.$route.query.health_id || !this.$route.query.id) {
                this.$router.push({
                    name: 'QualifyingQuestion'
                });
            }

            this.people.paitient_id = this.$route.query.id;
            this.people.id = this.$route.query.id;
            this.people.health_id = this.$route.query.health_id;
            this.people.phase = this.$route.query.phase;
            this.people.med = this.$route.query.plan;
            this.people.pay_terms = this.$route.query.pay_terms;
            this.people.state = this.$route.query.state;
            this.people.aff_id = this.$route.query.utm_campaign;
            this.people.forward_by_call = this.$route.query.forward_by_call;

            if (this.$route.query.discount == 50 || this.$route.query.discount == 100) {
                this.people.discount = this.$route.query.discount;
                this.discount = this.people.discount;
                this.showCoupon = false;
            }

            if (this.$route.query.aegate) {
                this.people.amount = atob((this.$route.query.aegate).replace(/-/g, '+').replace(/_/g, '/'))
            }else if(this.$route.query.plan=='Semaglutide'){
                this.people.amount = 299;
            }else{
                this.people.amount = 449;
            }

            if (this.$route.query.pay_terms) {
                if (this.people.pay_terms > 1) {
                    this.showCoupon = false;
                    this.discount = 0;
                    this.people.discount = 0;
                }
            }

            if (this.$route.query.callcenter_source) {
                this.people.callcenter_source = this.$route.query.callcenter_source
                this.showCall = true;
            }

            
            if (this.$route.query.coupon && this.people.pay_terms == 1) {
                this.coupon = this.$route.query.coupon
                this.checkCoupon();
            }

        },
        handleApplePay() {
            if (window.ApplePaySession) {
                this.addErr = false;
                if (this.people.zip == '' || this.people.zip == null) {
                    this.addErr = true;
                    return false;
                }
                const paymentRequest = {
                    countryCode: 'US',
                    currencyCode: 'USD',
                    supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
                    merchantCapabilities: ['supports3DS'],
                    total: {
                        label: 'Yara',
                        amount: (this.people.amount - this.discount),
                    },
                };
                const session = new window.ApplePaySession(3, paymentRequest);
                session.onvalidatemerchant = (event) => {
                    const validationURL = event.validationURL;
                    if (!validationURL) {
                        console.error('Validation URL is invalid or missing.');
                        session.abort();
                        return;
                    }
                    window.axios.post(this.baseUrl + '/validate-merchant', {
                        validationURL: validationURL
                    })
                        .then(response => {
                            const merchantSession = response.data.session;
                            if (merchantSession) {
                                session.completeMerchantValidation(merchantSession);
                            } else {
                                session.abort();
                            }
                        })
                        .catch(error => {
                            console.error('Error validating merchant:', error);
                            session.abort();
                        });
                };

                session.onpaymentauthorized = (event) => {
                    const payment = event.payment;
                    window.axios.post(this.baseUrl + '/get-payment-details?id=' + this.people.id + "&program=" + this.people.med + "&plan_price=" + this.people.amount + "&pay_terms=" + this.people.pay_terms+ "&phase=" + this.people.phase, {
                        token: payment.token
                    }).then(response => {
                        if (response.data.pay_resp == "APPROVED") {
                            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
                            let vm = this;
                            setTimeout(function () {
                                vm.pushToRoute();
                            }, 1000);
                            this.loading = false;
                        } else {
                            session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                        }

                    })
                };

                session.begin();
            } else {
                console.log("Apple Pay is not supported");
            }
        },
        updateAddress() {
            if (localStorage.getItem('formResponse')) {
                let formResponse = JSON.parse(localStorage.getItem('formResponse'));
                formResponse["med"] = this.people.med;
                formResponse["id"] = this.people.id;
                formResponse["health_id"] = this.people.health_id;
                formResponse["shipping"] = {
                    address_line_1: this.people.address_line_1,
                    address_line_2: this.people.address_line_2,
                    city: this.people.city,
                    state: this.people.state,
                    zip: this.people.zip,
                    full_address: this.people.full_address,
                }
                localStorage.setItem('formResponse', JSON.stringify(formResponse));
            }
            window.axios.post(this.baseUrl + '/save-address', this.people);
        },
        handleApplePays() {
            this.session.begin()
        },
        getCardToken() {
            this.addErr = false;
            this.loading = true;
            if (this.people.zip == '' || this.people.zip == null) {
                this.addErr = true;
                this.loading = false;
                return false;
            }
            this.errorReason = false;
            bt.tokens
                .create({
                    type: "card",
                    data: this.cardElement,
                })
                .then((token) => {
                    this.people.cardToken = token.id;
                    this.people.cardDetails = token.data;
                    this.submitForm();
                })
                .catch((error) => {
                    console.log(error);
                    this.errorReason = true;
                    this.loading = false;
                });
        },
        pushToRoute() {
            this.$router.push({
                name: 'AfterCheckout',
                query: {
                    "health_id": this.$route.query.health_id,
                    "id": this.$route.query.id,
                    "plan": this.people.med,
                    "state": this.people.state,
                }
            });
            this.getAffiliateAndSave();
        },
        submitForm() {
            this.loading = true;
            this.people.program = this.people.med;
            window.axios.post(this.baseUrl + '/create-payment-profile', this.people).then((response) => {
                if (response.data.status == 'success' && response.data.pay_resp == "APPROVED") {
                    localStorage.setItem("checkoutForm", JSON.stringify(this.people));
                    this.pushToRoute();
                    this.loading = false;
                } else {
                    this.errorReason = true;
                    this.errorMessage = response.data.pay_resp;
                    this.loading = false;
                }
            }).catch((error) => {
                console.log(error);
                this.loading = false;
            });
        },
        getAddressData(place) {
            // Get selected address details
            this.people.address_line_1 += place.street_number + ' ' + place.route;
            this.people.city = place.locality;
            this.people.state = place.administrative_area_level_1;
            this.people.zip = place.postal_code;
            if(this.people.city==undefined){
              this.people.city = place.administrative_area_level_2;
            }
            localStorage.setItem("userState", JSON.stringify(this.people.state));
            this.updateAddress();
        },
        focusNextInput(nextIndex) {
            const inputs = document.querySelectorAll('.ph_one');
            console.log(inputs);
            if (nextIndex < inputs.length) {
                inputs[nextIndex].focus();
            }
        },
        focusNext(event) {
            const currentInput = event.target;
            const nextInput = currentInput.nextElementSibling;
            const prevInput = currentInput.previousElementSibling;
            console.log(nextInput);
            if (event.key === "Backspace" && currentInput.value === "") {
                if (prevInput) {
                    prevInput.focus();
                }
            } else if (currentInput.value.length === currentInput.maxLength && nextInput) {
                nextInput.focus();
            }
        },
        createCardElement() {
            this.cardElement = bt.createElement('card');
            this.cardElement.mount('#card-element');
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
            return null;
        },
        getQueryParamOrCookie(url, paramName) {
            const urlObj = new URL(url);
            const params = new URLSearchParams(urlObj.search);
            let value = params.get(paramName);
            if (value !== null) {
                return value;
            }
            value = this.getCookie(paramName);
            return value;
        },
        getAffiliateAndSave() {
            try {
                const url = window.location.href;
                const affiliateIdKey = 'utm_campaign';
                const tidKey = 'tid';
                const utm_sourceKey = 'utm_source';
                const affiliateId = this.getQueryParamOrCookie(url, affiliateIdKey);
                const tid = this.getQueryParamOrCookie(url, tidKey);
                const utm_source = this.getQueryParamOrCookie(url, utm_sourceKey);
                var parameterWithValue= null;
                var userAgent = "";
                
                if (this.people.forward_by_call) {
                    parameterWithValue = this.people.forward_by_call;
                } else if (this.people.callcenter_source) {
                    parameterWithValue = this.people.callcenter_source;
                }

                try {
                    userAgent = navigator.userAgent
                } catch (error) {
                    console.log(error)
                }
                window.axios.post(this.baseUrl + '/register-affiliate', {
                    "id": this.people.id,
                    "affiliate_id": affiliateId,
                    "utm_source": utm_source,
                    "tid": tid,
                    "discount": this.discount,
                    "med": this.people.med,
                    "state": this.people.state,
                    "amount": this.people.amount,
                    "pay_terms": this.people.pay_terms,
                    "userAgent": userAgent,
                    "ip_address": JSON.parse(localStorage.getItem("ip_address")),
                    "callcenter_source": parameterWithValue,
                    "forward_by_call": parameterWithValue,
                });
            } catch (error) {
                console.log(error)
            }

        }
    },

}
</script>

<style>
.ifg_right{
    background: rgba(238, 251, 251, .9) !important;
}

.selected {
    background-color: #002646 !important;
    border-width: 3px !important;
    border-color: #26ced1 !important;
    border-radius: 8px !important;
    color: #FFFFFF !important;
}

.myelement {
    border: 1px solid #002646;
    border-radius: 6px;
    padding: 5px 0 5px 15px;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #333333;
    background-color: #ffffff;
}

.open-oprions {
    display: block !important;
}

.notselected {
    color: #000 !important;
}

@media only screen and (max-width: 468px) {
    .showdesk {
        display: none;
    }

    .top-content-container.tirzep {
        background-image: none !important;
        background-size: 17%;
        padding-bottom: 0;
    }
}

@media only screen and (min-width: 768px) {
    .showmobile {
        display: none;
    }
}

.pac-target-input {
    height: 50px;
    border-width: 2px;
    border-color: #002646;
    border-radius: 3px;
    width: 100%;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    vertical-align: middle;
    background-color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .hideipad {
        display: none;
    }

    .ipad {
        width: 100% !important;
    }
}

.indexcls {
    z-index: 901;
}

.mkeup {
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.show {
    display: block;
}

.hide {
    display: none !important;
    animation: slideOutToBottom 0.5s ease forwards;
}

.invalid {
    color: red !important;
    border: 2px solid red !important;
}

@media screen and (max-width: 479px) {
    .remove-gap {
        flex-flow: column;
        align-items: center;
        margin-bottom: 30px;
        padding-left: 3%;
        padding-right: 3%;
        display: flex;
        margin-top: -60px !important;
    }
}
</style>