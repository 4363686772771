<template>
<div>
    <div id="mygalModal" aria-hidden="true" class="modal fade move-up in intro-modal modal-for-gal" style="display: block;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="new-modal">
                    <div class="modal_down bacground_sdo">
                        <div class="intro-modal-body">
                            <div class="intro-modal-heading"><strong>Update Card</strong> </div>
                            <div class="trial-details">
                                <div class="preview" style="padding-top: 35px !important;">
                                    <div id="card-element" class="myelement" ref="myElement"></div>
                                </div>
                                <div class="footer-button" style="padding-top: 35px !important;">
                                    <div class="div-block-1120">
                                        <a href="#" @click="getCardToken($event)" class="button-44 w-button dash-button">
                                            <span v-if="submitted"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="15px" height="15px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                    <circle cx="50" cy="50" fill="none" stroke="blue" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.53475935828877s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                                    </circle>
                                                </svg></span>
                                            <span v-else>Update Card</span></a>
                                        <a href="#" @click="CloseDialog($event)" class="button-45 w-button dash-button">Cancel</a>
                                    </div>
                                </div>
                                <div class="w-form-fail" style="display: block;" v-if="errorReason">
                                    <div>Invalid card details.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    BasisTheory
} from "@basis-theory/basis-theory-js";
const bt = await new BasisTheory().init("key_prod_us_pub_9drv27VKwcUVoP5BNnnyKZ", {
    elements: true
});
export default {
    props: ["baseUrl"],
    data() {
        return {
            mykey: 1,
            token: null,
            errorReason: '',
            submitted: false,
        }
    },
    mounted() {
        this.createCardElement();
    },
    methods: {
        getCardToken(event) {
            event.preventDefault();
            this.errorReason = false;
            this.submitted = true;
            bt.tokens
                .create({
                    type: "card",
                    data: this.cardElement,
                })
                .then((token) => {
                    this.token = token;
                    this.updateCard();
                })
                .catch((error) => {
                    console.log(error);
                    this.errorReason = true;
                    this.submitted = false;
                });
        },
        createCardElement() {
            this.cardElement = bt.createElement('card');
            this.cardElement.mount('#card-element');
        },
        CloseDialog(event) {
            event.preventDefault();
            this.$emit("CloseBox");
        },
        updateCard() {
            window.axios.post(this.baseUrl + '/update-card', {
                    token: this.token.id,
                    details: this.token.data
                })
                .then(response => {
                    console.log(response.data)
                    this.$emit("ShowToast");
                })
                .catch(error => {
                    console.log(error)
                });
        }
    },
    created() {
        //this.setUpStripe();
    }
}
</script>

<style scoped>
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

}

.intro-modal-body {
    justify-content: center;
}

.intro-modal-heading {
    font-size: 18px;
    display: flex;
    justify-content: center;
}

.modal-content {
    background-color: #fefefe;
    border-radius: 10px;
    max-width: 468px;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.dash-button {
    height: 45px;
}
</style>
