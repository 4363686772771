<template>
<div id="app">
    <router-view :baseUrl="baseUrl" :mainlogo="logo" :footlogo="foot_logo" :checkoutlogo="checkout_logo" :iconSVGColor="iconSVGColor"></router-view>
    <footer-section :footlogo="foot_logo" v-if="checkSecheule"/>
</div>
</template>

<script>
import FooterElement from './components/common/footerElement.vue'
export default {
    name: 'App',
    components: {
        'footer-section': FooterElement,
    },
    data() {
        return {
            baseUrl: "https://serverforge.joinyara.com/api",
            logo: 'images/yara-placeholder.svg',
            foot_logo:'images/yara-placeholder-W.svg',
            checkout_logo:'images/yara-placeholder.svg',
            iconSVGColor: ""
        }
    },
    computed: {
        isLoggedin() {
            return this.$store.getters.isAuthenticated;
        },
        getRouteName() {
            let rname = this.$route.name;
            var compact_menu = ["RefillQuestions", "ThankYouRevisit", "CheckoutForm", "Calendar", "ThanksAppointment", "HomePage", "LoginPage", "ForgotPassword", "ResetPassword"];
            if (compact_menu.includes(rname)) {
                return true;
            } else {
                return false;
            }

        },
        checkSecheule() {
            let rname = this.$route.name;
            var compact_menu = ["Calendar","HelpCalendar"];
            if (compact_menu.includes(rname)) {
                return false;
            } else {
                return true;
            }

        }

    },
    watch: {
        isLoggedin: function (newVal, oldVal) {
            console.log(oldVal)
            this.pushToRoute(newVal);
        },

    },
    mounted() {
        this.updateCSSVariables();
    },
    methods: {
        updateCSSVariables() {
            const hostname = window.location.hostname;//
            if (hostname.includes('ifg.joinyara.com')) {
                document.documentElement.style.setProperty('--form-header-background', "#0B0331");
                document.documentElement.style.setProperty('--form-button', "#316ff6");
                document.documentElement.style.setProperty('--form-background', "#0B0331");
                document.documentElement.style.setProperty('--footer-bg', "#000");
                document.documentElement.style.setProperty('--counter-color', "#FFFFFF");
                document.documentElement.style.setProperty('--font-family', "League Spartan, sans-serif");
                document.documentElement.style.setProperty('--body-font', "League Spartan, sans-serif");
                document.documentElement.style.setProperty('--girl-background', "#316ff6");
                document.documentElement.style.setProperty('--heading-color', "#FFFFFF");
                document.documentElement.style.setProperty('--main-heading-color', "#316ff6");
                document.documentElement.style.setProperty('--points-color', "#FFFFFF");
                document.documentElement.style.setProperty('--hover-color', "#316ff6");
                document.documentElement.style.setProperty('--box-hover-color', "#EEFBFB");
                document.documentElement.style.setProperty('--svg-fill-color', "#26CED1");
                document.documentElement.style.setProperty('--logo-width', "200px");
                document.documentElement.style.setProperty('--media-logo-size', "135px");
                document.documentElement.style.setProperty('--after-qualify-mobile-logo-width', "145px");
                document.documentElement.style.setProperty('--footer-logo-size', "120px");
                document.documentElement.style.setProperty('--hover-color-buttons', "#EEFBFB");
                document.documentElement.style.setProperty('--wcheck-buttons', "#FFFFFF");
                document.documentElement.style.setProperty('--under-line', "#316FF6");
                document.documentElement.style.setProperty('--right-window', "rgba(238, 251, 251, .9)");
                document.documentElement.style.setProperty('--checkout-right', "rgba(238, 251, 251, .9)");
                document.title = "It Feels Good";
                this.iconSVGColor="_ifg";
                this.logo="/images/IFGpoweredbyYara_white.png";
                this.foot_logo="/images/IFGpoweredbyYara_white.png";
                this.checkout_logo="/images/IFGpoweredbyYara_black.png";
            }else{
                document.documentElement.style.setProperty('--right-window', "rgba(255, 218, 189, .9)");
            }

        },
        pushToRoute(val) {
            console.log(val);

        },
    },
}
</script>

<style>
.hidden-widget {
    display: none;
}
</style>
