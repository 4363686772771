<template>
<div class="ifg-cls">
    <section>
        <div class="after-qualify-header-div">
            <a href="#" class="w-inline-block"><img loading="lazy" :src="checkoutlogo" alt="" class="after-qualify-top-logo"></a>
        </div>
    </section>
    <section>
        <div class="content-main-div">
            <div class="w-layout-blockcontainer box-links-container w-container">
                <div class="div-block-1316">
                    <div class="after-qualify-header">You are pre-qualified, {{first_name}}!</div>
                    <div class="after-qualify-subheader" v-if="showOptions">Choose one of the options below:</div>
                </div>
                <div class="options-div">
                    <a href="#" @click="pushToRoute($event, 'checkout')" class="option-link _3 w-inline-block" v-if="showOptions">
                        <div class="div-block-1317"><img loading="lazy" :src="getIconPath('icon_meds-2')" alt="" class="image-259">
                            <div>
                                <div class="text-block-383" v-if="isMobile"><span class="text-span-89">Choose Plan. Get Started.</span></div>
                                <div class="text-block-383" v-else><span class="text-span-89">Choose Plan & Get Started Today.</span></div>
                                <p class="paragraph-82">Skip the consult, <strong>choose a medical weight loss program, meet with a doctor</strong> today or tomorrow &amp; <strong>have your meds in 3 days.</strong></p>
                            </div>
                        </div>
                        <div class="div-block-1318">
                            <div class="text-block-385">Click to check out</div>
                            <div class="text-block-384">-&gt;</div>
                        </div>
                    </a>
                    <a href="https://m.me/61555827737819" target="_blank" class="option-link _2 w-inline-block"  v-if="showOptions">
                        <div class="div-block-1317"><img loading="lazy" src="@/assets/images/icon_chat.svg" alt="" class="image-259">
                        <div>
                            <div class="text-block-383" v-if="isMobile"><span class="text-span-89">Chat now with Yara AI.</span></div>
                            <div class="text-block-383" v-else><span class="text-span-89">Chat Now w/ the Yara AI Assistant.</span></div>
                            <p class="paragraph-82"><strong>Click to chat on FB messenger with the Yara AI Assistant</strong> who can answer questions and direct you to the best resources.</p>
                        </div>
                        </div>
                        <div class="div-block-1318">
                        <div class="text-block-385">Click to Chat</div>
                        <div class="text-block-384">-&gt;</div>
                        </div>
                    </a>
                    
                    <a href="#" @click="pushToRoute($event, 'help')" class="option-link _1 w-inline-block" style="margin-top: 1px;">
                        <div class="div-block-1317"><img loading="lazy" :src="getIconPath('icon_support')" alt="" class="image-259">
                            <div>
                                <div class="text-block-383" v-if="isMobile"><span fo="" class="text-span-89">Talk to a Care Navigator.</span></div>
                                <div class="text-block-383" v-else><span fo="" class="text-span-89">Book intro call w/ a Care Navigator.</span></div>
                                <p class="paragraph-82"><strong>Schedule a short 5 minute call to review medical weight loss options</strong> and ask all of your questions before you commit.</p>
                            </div>
                        </div>
                        <div class="div-block-1318">
                            <div class="text-block-385">Click to Schedule</div>
                            <div class="text-block-384">-&gt;</div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
    <div class="included-space" style="height:280px;" v-if="!showOptions"></div>
</div>
</template>

<script>
export default {
    props: ['baseUrl', 'mainlogo', 'iconSVGColor','checkoutlogo'],
    components: {

    },
    data() {
        return {
            showOptions: true
        }
    },
    computed: {
        isMobile(){
            return window.innerWidth <= 768;
        },
        first_name() {
            try {
                return this.$route.query.name;
            } catch (error) {
                console.log(error);

            }
            return "";
        }
    },
    mounted() {

    },
    methods: {
        
        getIconPath(path) {
            const images = require.context('@/assets/images', false, /\.svg$/);
            return images(`./${path}${this.iconSVGColor}.svg`);
        },
        pushToRoute(event, name) {
            event.preventDefault();
            if (name == 'help') {
                this.$router.push({
                    name: 'HelpCalendar',
                    query: {
                        "health_id": this.$route.query.health_id,
                        "id": this.$route.query.id,
                        "name": this.$route.query.name,
                        "phase": this.$route.query.phase,
                        "plan": this.$route.query.plan,
                        "state": this.$route.query.state
                    }
                });
            } else {
                this.$router.push({
                    name: 'CheckoutForm',
                    query: {
                        "health_id": this.$route.query.health_id,
                        "id": this.$route.query.id,
                        "name": this.$route.query.name,
                        "phase": this.$route.query.phase,
                        "plan": this.$route.query.plan,
                        "state": this.$route.query.state
                    }
                });
            }

        }
    },
    created() {
        
    }
}
</script>
