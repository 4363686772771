<template>
    <div class="containercal" v-bind:class="{ 'time-div-active': avltime.length > 0 }">
        <section class="description-section">
            <hgroup>
                <h4 id="scheduler" style="color:black;font-size:18px;"><img src="@/assets/images/girl_yara.png" width="56" sizes="56px" alt="" class="imagecal"></h4>
                <h2 id="event" class="eventcls">Schedule an initial consult <br/>with a Yara care navigator.</h2>
                <div class="icon-text-div">
                    <img src="@/assets/images/clock.svg" alt="clock-icon">
                    <h4 id="duration">5 - 10 min</h4>
                </div>
            </hgroup>
            <p id="description" class="mobdescription">The meeting will take 5-10 minutes and we will help you review all your options and answer any questions you may have.</p>

        </section>
        <div class="divider"></div>
        <section id="calendar-section" class="body-section" v-bind:class="{ 'flexdivs': avltime.length > 0 }">
            <h3 class="seldate-txtschedule">Select a Date & Time</h3>
            <div id="schedule-div">
                <div id="available-times-div" v-bind:class="{ 'showTimes': avltime.length > 0 }">
                    <h4><button class="fc-prev-button fc-button fc-button-primary" v-if="ismob" type="button"
                            @click="avltime = []" aria-label="prev"><span
                                class="fc-icon fc-icon-chevron-left"></span></button>{{ getDateName() }}</h4>
                    <div :key="keyval" class="date-select-button">
                        <div v-for="(tim, index) in avltime" :key="index">
                            <div class="time-slot" v-if="selected == index">
                                <button class="time-btn">{{ tConvert(tim) }}</button><button
                                    @click="selectedtimeforapp(tim, tConvert(tim))" class="confirm-btn">Confirm</button>
                            </div>
                            <div class="time-slot" v-else>
                                <button class="time-btn" @click="myactivity(index)">{{ tConvert(tim) }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="calendar" :key="dateKey" v-if="!loading">
                    <mycalendar :mindate='mindate' :localtimezones="userTimeZone" :month="month" :year="year"
                        @getFreeTime='selectedDate' :disableddates='getDays' :availDates="availDates"
                        @setTimezone="setTimezone" @getNewDatesForPrevious="getNewDatesForPrevious"
                        @getNewDatesForNext="getNewDatesForNext" />
                </div>
                <div v-else>
                    <loadingOverlay></loadingOverlay>
                </div>
            </div>
        </section>

        <loadingOverlay v-if="timeLoading"></loadingOverlay>
    </div>
</template>

<script>
import {
    isMobileOnly
} from 'mobile-device-detect';
import mycalendar from './calTwo.vue'
import listoftimezones from '../Calendar/MTimezones.json'
import statesTimeZone from '../Calendar/state_timezone.json'
import loadingOverlay from '../common/loadingScreen.vue';
import moment from 'moment-timezone';

export default {
    props: ['calset', 'uid', 'pid', 'baseURL', 'visit_type'],
    components: {
        mycalendar,
        loadingOverlay
    },
    data() {
        return {
            logo: '',
            modeltext: "Finding Current Available Times...",
            show: true,
            selectabetimezone: listoftimezones,
            statesTime: statesTimeZone,
            ismob: isMobileOnly ? true : false,
            userTimeZone: '',
            timeLoading: false,
            availDates: [],
            loading: true,
            avltime: [],
            keyval: 0,
            mindate: '',
            dateKey: 0,
            currentDate: '',
            selected: ''
        }
    },
    computed: {
        month() {
            const date = new Date(this.currentDate);
            const monthName = date.toLocaleString('en-US', { month: 'long' });
            return monthName;
        },
        year() {
            const date = new Date(this.currentDate);
            const year = date.getFullYear();
            return year;
        },
        minindex() {
            let k = this.user_start.split(':');
            let v = k[0] * 60 + parseInt(k[1]);
            return v;
        },
        maxindex() {
            let k = this.endtime.split(':');
            let v = k[0] * 60 + parseInt(k[1]);
            return v;
        },
        getDate() {
            console.log(this.val);
            return moment(this.val).format("dddd, MMMM Do")
        },
        getDays() {
            //var a1 = this.user_non_avail;
            //let a2 = [0, 1, 2, 3, 4, 5, 6];
            return []; //a2.filter(d => !a1.includes(d))
        },
    },
    mounted() { },
    methods: {
        getNewDatesForPrevious() {
            const date = new Date(this.currentDate);
            date.setMonth(date.getMonth() - 1);
            this.currentDate = date.toISOString().split('T')[0];
            this.getAvailableDates();
        },
        getNewDatesForNext() {
            const date = new Date(this.currentDate);
            date.setMonth(date.getMonth() + 1);
            this.currentDate = date.toISOString().split('T')[0];
            this.getAvailableDates();
        },
        setTimezone(val) {
            this.userTimeZone = val;
            this.getAvailableDates();
            this.keyval++;

        },
        getDateName() {
            const date = moment(this.seldate, 'YYYY/M/DD');
            return date.format('dddd MMMM Do');
        },
        selectedtimeforapp(time, converted) {
            localStorage.setItem("selTimeZone", this.userTimeZone);
            localStorage.setItem("help_slot", JSON.stringify(time));
            this.$emit('SelectedTime', converted, this.val);
        },
        tConvert(slot) {

            const dateTime = new Date(slot.dateIso);
            const timeZoneOptions = { timeZone: this.userTimeZone };
            const hours = dateTime.toLocaleString('en-US', { hour: 'numeric', hour12: false, ...timeZoneOptions });
            const minutes = dateTime.toLocaleString('en-US', { minute: 'numeric', ...timeZoneOptions });
            const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
            const [hour] = formattedTime.split(':').map(Number);
            const hour12 = (hour % 12 === 0) ? 12 : hour % 12;
            const period = (hour < 12) ? 'AM' : 'PM';
            const formattedMinute = (minutes < 10) ? `0${minutes}` : minutes;
            return `${hour12}:${formattedMinute} ${period}`;
        },
        myactivity(index) {
            this.selected = index;
        },
        goback() {
            this.stepcheck--;
        },
        selTimeUpdate(tim) {
            this.seltime = tim;
            this.stepcheck++;
        },
        selectedDate(data, da, yr, mon) {
            this.seldate = data;
            this.val = data;

            this.seld = yr + " - " + mon + "-" + da;
            if (da < 10) {
                this.seld = yr + " - " + mon + "-0" + da;
            }
            this.checktime();
        },
        checktime() {
            this.show = true;
            this.gettingtime = 1;
            this.timeLoading = true;
            let data = JSON.stringify({
                "start_date": this.seldate,
                "date": this.seldate,
                "timezone": this.userTimeZone
            });
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: this.baseURL + '/help-open-slots',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            window.axios.request(config)
                .then((response) => {
                    this.avltime = response.data.availableSlotsForRange;
                    this.timeLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        isTimeZoneInList(targetTimeZone) {
            return listoftimezones.some(timezone => timezone.type === targetTimeZone);
        },
        getUserTimezone() {
            try {
                let stateAbbreviation = JSON.parse(localStorage.getItem("userState"));
                const timezone = Object.entries(this.statesTime).find(([abbr]) => abbr === stateAbbreviation.toUpperCase())?.[1];
                if (timezone) {
                    return timezone;
                }
                if (this.isTimeZoneInList(Intl.DateTimeFormat().resolvedOptions().timeZone)) {
                    return Intl.DateTimeFormat().resolvedOptions().timeZone
                } else {
                    return "America/New_York";
                }
            } catch (error) {

                return "America/New_York";
            }

        },
        getAvailableDates() {
            let data = JSON.stringify({
                "date": this.currentDate,
                "timezone": this.userTimeZone,
            });

            let config = {
                method: 'post',
                url: this.baseURL + '/help-get-days',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            window.axios.request(config)
                .then((response) => {
                    this.availDates = response.data.daysAvailableForRange;
                    this.loading = false;
                    this.dateKey++;
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    },

    created() {
        this.userTimeZone = this.getUserTimezone();
        const date = new Date();
        this.currentDate = date.toISOString().split('T')[0];
        this.getAvailableDates();
    },
}
</script>

<style scoped>
@import "../../assets/css/main.css";
@import "../../assets/css/styles.css";
@import "../../assets/css/schedule.css";

.showTimes {
    display: initial !important;
    overflow-y: scroll !important;
    padding-left: 15px !important;
}

.showTimes::-webkit-scrollbar {
    -webkit-appearance: none;
}

.flexdivs {
    flex: 2 1 0%;
}

.time-btn {
    font-size: 14px;
    font-weight: 600;
}

.confirm-btn {
    font-size: 14px;
    font-weight: 600;
    background-color: #3898EC;
    color: #fff;
}

.body-section {
    flex: 1;
    margin: 30px 30px 30px 0px;
}

.description-section {
    border-right: 1px solid #dfdddd;
    padding-right: 30px;
}

div#available-times-div {
    margin-right: -15px;
}

#available-times-div {
    margin-left: 15px;
}
.time-div-active .description-section {
    max-width: 33%;
    border-right: 1px solid #dfdddd;
    padding-right: 30px;
}


/* width */
::-webkit-scrollbar {
  width: 8px;

  
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(26 26 26 / 61%);
  border-radius: 10px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.date-select-button {
        padding-right: 20px;
    }


@media (max-width: 767px) {
    .description-section {
        padding: 0;
        margin: 30px 5%;
        margin-top: 0px;
    }
    .mobdescription {
        margin-bottom: 5px;
    }
    .body-section {
        flex: 1;
        margin: 30px 0px 30px 0px;
    }
    .time-div-active .description-section {
        max-width: 100%;
        border-right: 0px solid #dfdddd;
        padding-right: 0px;
    }

    #calendar {
        max-width: 339px;
    }
    .date-select-button {
            padding-left: 28px;
        }
    #calendar-section .fc-toolbar-title {
        font-size: 1.2rem;
        }
    .seldate-txtschedule {
        font-size: 18px;
    }
    .description-section {
    border-right: 1px solid #fff;
   
    }
}

@media (min-width: 768px) {
    .date-select-button {
        max-height: 540px;
        overflow: auto;
        box-sizing: border-box;
    }


}

</style>
